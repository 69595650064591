import { Entities } from "../../common/constants";
import { catchErrorClbk, entityUrlBuilder, ServerResponse } from "../../hoc/dalFactory";
import queryString from "query-string";
import axios from "axios";
import { UserCountResult } from "../../common/interfaces";

export const userCount = async (filters?: {
  groupBy?: string;
  origin?: string;
  fromDate?: string;
}): Promise<ServerResponse<UserCountResult[]>> => {
  try {
    const url = `${entityUrlBuilder(Entities.Users)}/count${filters ? `?${queryString.stringify(filters)}` : ""}`;
    const result = await axios.get<UserCountResult[]>(url);
    return { data: result.data };
  } catch (error) {
    return { error: catchErrorClbk(error) };
  }
};

export const userLoginCount = async (filters?: { fromDate?: string }): Promise<ServerResponse<UserCountResult[]>> => {
  try {
    const url = `${entityUrlBuilder(Entities.Users)}/count/login${filters ? `?${queryString.stringify(filters)}` : ""}`;
    const result = await axios.get<UserCountResult[]>(url);
    return { data: result.data };
  } catch (error) {
    return { error: catchErrorClbk(error) };
  }
};
