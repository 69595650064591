import { FC, useEffect, useState } from "react";
import { DesignMap } from "../../common/components/DesignMap/DesignMap";
import { IMapPoint } from "../../common/components/DesignMap/DesignMap.interface";
import { FlexContainer } from "../../common/components/FlexContainer";
import { Loading } from "../../common/components/Loading";
import { useMountEffect } from "../../common/customHooks/useMountEffect";
import { Asset, Winery } from "../../common/interfaces";
import { WineryPopup } from "../../common/components/DesignMap/MarkerPopup";
import { mapDal, markerDal } from "./WineriesMap.dal";
import { getMarkerIcon } from "../../common/components/DesignMap/MarkerIcon";
import { nameof } from "../../utils";
import { Radio } from "antd";

export const WineriesMap: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Winery[]>();
  const [markers, setMarker] = useState<Asset[]>();
  const [points, setPoints] = useState<IMapPoint[]>();
  const [mapsType, setMapsType] = useState<"world" | "street">("street");

  useMountEffect(() => {
    setLoading(true);
    markerDal.get().then((result) => {
      if (result && result.data) {
        setMarker(result.data.data || []);
      }
    });

    mapDal
      .get({
        filter: `${nameof<Winery>("type")} = 1 OR ${nameof<Winery>("type")} = 2 OR ${nameof<Winery>(
          "type"
        )} = 3 OR ${nameof<Winery>("type")} = 6`,
      })
      .then((result) => {
        if (result && result.data) {
          setData(result.data.data || []);
        }
      });
  });

  useEffect(() => {
    const buildMapMarkers = (wineries: Winery[]): IMapPoint[] | undefined => {
      return wineries.map((w) => {
        return {
          pos: { lat: w.location?.latitude || 0, lng: w.location?.longitude || 0 },
          popupContent: WineryPopup(w),
          icon: markers && getMarkerIcon(w.region || "", markers),
        };
      });
    };

    if (markers && data && loading) {
      setLoading(false);
      setPoints(buildMapMarkers(data));
    }
  }, [markers, data, loading]);

  const onChange = (e: any) => {
    if (e.target.value) setMapsType(e.target.value);
  };

  return (
    <FlexContainer>
      <div style={{ width: "100%", paddingTop: 5, paddingLeft: 5, paddingRight: 5 }}>
        <Radio.Group onChange={onChange} value={mapsType}>
          <Radio value={"world"}>World</Radio>
          <Radio value={"street"}>Street</Radio>
        </Radio.Group>
      </div>
      <div
        style={{ width: "100%", height: window.innerHeight - 35, paddingBottom: 15, paddingLeft: 5, paddingRight: 5 }}
      >
        {!loading && data && <DesignMap points={points} zoom={6} mapsType={mapsType} reduceHeightPx={35} />}
        {loading && <Loading />}
      </div>
    </FlexContainer>
  );
};
