import { Entities } from "../../common/constants";
import { entityDalFactory, GenericDalOperation, IDalCRUDSchema, IDalCRUDSchemaWithExport } from "../../hoc/dalFactory";
import { Asset, Winery, WineryDataOutputData } from "../../common/interfaces";

export const wineriesDal = entityDalFactory<Winery, IDalCRUDSchemaWithExport<Winery>>({
  enableOperation: GenericDalOperation.CRUD,
  entityName: Entities.Wineries,
  enableSchema: true,
  enableExport: true,
});

export const markerDal = entityDalFactory<Asset, IDalCRUDSchema<Asset>>({
  enableOperation: GenericDalOperation.CRUD,
  entityName: Entities.Assets,
  enableSchema: true,
});

export const wineriesDataDal = entityDalFactory<Winery, IDalCRUDSchema<WineryDataOutputData>>({
  enableOperation: GenericDalOperation.CRUD,
  entityName: `${Entities.Wineries}/data`,
  enableSchema: true,
  enableExport: true,
});
