import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { Roles } from "../../common/constants";
import { useAdministrativePage } from "../../common/customHooks/useAdministrativePage";
import { useReadOnlyPage } from "../../common/customHooks/useReadOnlyPage";
import { entityTableCRUDFactory } from "../../hoc/TableFactory";
import { cols } from "./FormSchemas.constants";
import { formSchemaDal } from "./FormSchema.dal";

const FormSchemaTable = entityTableCRUDFactory({
  tableId: "form-schemas",
  dal: formSchemaDal,
  cols,
  formUrl: "form-schema",
  addEnabled: true,
  editEnabled: true,
  deleteEnabled: true,
  useTableDataForEditing: true,
});

export const FormSchemas = () => {
  useAdministrativePage();
  const readOnly = useReadOnlyPage([Roles.Staff, Roles.Admin]);

  return (
    <FlexContainer>
      <FlexPanelMiddle title={"Form Schemas Registry"} panelClassName="md-card-3">
        <FormSchemaTable canExport={false} canDelete={!readOnly} canEdit={!readOnly} />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
