import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  CheckCircleTwoTone,
  CheckOutlined,
  CloseCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { Button, Input, Space, Table, Tooltip, Upload } from "antd";
import {
  ExportingDataType,
  IDalCRUDBulkSchema,
  IDalCRUDSchema,
  IDalCRUDSchemaWithExport,
  ServerError,
} from "../dalFactory";
import { useHistory } from "react-router";
import { openNotificationWithIcon } from "../../common/components/Notification";
import {
  FilterDropdownProps,
  ColumnType,
  TablePaginationConfig,
  FilterValue,
  SorterResult,
} from "antd/lib/table/interface";
import Highlighter from "react-highlight-words";
import { ColumnsDataType, ITableColumn, ITableCrudFactoryOptions, ITableCrudProps } from "./GenericTable.interfaces";
import { useMountEffect } from "../../common/customHooks/useMountEffect";
import { useStateWithLocalStorage } from "../../common/customHooks/useStateWithStorage";
import { RcFile } from "antd/lib/upload";
import { Loading } from "../../common/components/Loading";

export const renderBooleanDataFunction = (d: any) =>
  d && Boolean(d) ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />;

export const renderDateStringFunction = (d: string) =>
  d && d.split("T") && `${d.split("T")[0]}, ${d.split("T")[1].split(".")[0]}`;

export const renderEnumDataColsFactory = (enumType: any, split?: string) => (d: any) => {
  const value = enumType[d] ? enumType[d] : d;
  return split ? `${d} - ${(value as string).split(split).join(" ")}` : value;
};

export const enumeratorFilterColFactory = (enumType: any) => {
  const values = Object.values(enumType);
  const descr = values.filter((v) => isNaN(Number(v)));
  const numb = values.filter((v) => !isNaN(Number(v)));
  const array = [];
  for (let i = 0; i < descr.length; i++) {
    array.push({ text: `${numb[i]} - ${descr[i]}`, value: numb[i] });
  }
  return array;
};

export const booleanFilterColFactory = () => {
  return [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];
};

export const colsDataIndexs = <T extends object>(cols: ITableColumn<T>[]) =>
  cols.map((c) => {
    return { dataIndex: c.dataIndex as string, types: c.dataType };
  });

export const entityTableCRUDFactory = <T extends { _id: string; hash?: number }>({
  dal,
  dalR,
  cols,
  formUrl,
  tableTitle,
  tableFooter,
  editEnabled,
  deleteEnabled,
  addEnabled,
  selectionType,
  tableId,
  useTableDataForEditing,
}: ITableCrudFactoryOptions<T>): React.FC<ITableCrudProps<T>> => {
  return function TableCrud(props: ITableCrudProps<T>) {
    const [data, setData] = useState<T[] | undefined | null>();
    const [selectedRow, setSelectedRow] = useState<T[]>();
    const [filters, setFilters] = useStateWithLocalStorage<string | undefined>(`${tableId}-filters`);
    const [sorters, setSorters] = useStateWithLocalStorage<string | undefined>(`${tableId}-sorters`);
    const [page, setPage] = useStateWithLocalStorage<number>(`${tableId}-page`);
    const [pageSize, setPageSize] = useStateWithLocalStorage<number>(`${tableId}-pageSize`);
    const [toCancel, setToCancel] = useState<T[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>("");
    const [searchedColumn, setSearchedColumn] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [importData, setImportData] = useState<T[]>();
    const searchInput = useRef<Input>(null);
    const history = useHistory();
    const { customActions, canExport, canDelete, canEdit } = props;

    /** Effects */
    useMountEffect(() => {
      getData(filters, sorters, page, pageSize);
    });

    /** Callbacks */
    const getData = useCallback((filter?: string, sorter?: string, pageNumber?: number, size?: number) => {
      setLoading(true);
      const selectedDal = dalR || dal;
      selectedDal
        .get({ filter, orderBy: sorter, pageNumber, pageSize: size || 20 })
        .then((result) => {
          if (result.error && result.error.httpStatusCode === 404) {
            setData([]);
          } else if (result.data) {
            const { data, totalRecords } = result.data;
            setData(data);
            setTotalRecords(totalRecords);
          }
          if (pageNumber && pageNumber !== page) setPage(pageNumber);
          if (size && size !== pageSize) setPageSize(size);
          if (filter !== filters) setFilters(filter);
          if (sorter !== sorters) setSorters(sorter);
          setLoading(false);
        })
        .catch((_) => {
          setData([]);
          setLoading(false);
        });
      // eslint-disable-next-line
    }, []);

    /** Columns and filters building */
    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
      clearFilters();
      setSearchText("");
    };

    const getColumnStringSearchProps = (dataIndex: string): Partial<ColumnType<T>> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilterDropdownVisibleChange: (visible: any) => {
        if (visible) {
          setTimeout(() => searchInput.current && searchInput.current.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    const colsWithFilters = cols.map((c) => {
      const stringSearchFilter =
        c.dataType === ColumnsDataType.string ? getColumnStringSearchProps(c.dataIndex as string) : {};
      return {
        ...c,
        ...stringSearchFilter,
      };
    });

    const colsWithFiltersAndActions = [
      ...colsWithFilters,
      (editEnabled && canEdit) || (deleteEnabled && canDelete) || customActions
        ? ({
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_text: string, record: T) => (
              <Space size="middle">
                {editEnabled && (canEdit !== undefined ? canEdit : true) && (
                  <Tooltip title="Edit element">
                    <EditOutlined
                      onClick={() =>
                        history.push(`/${formUrl}/${record._id}`, {
                          record: useTableDataForEditing ? record : undefined,
                          tableData: { filters, sorters, page, pageSize },
                        })
                      }
                    />
                  </Tooltip>
                )}
                {deleteEnabled && (canDelete !== undefined ? canDelete : true) && (
                  <Tooltip title="Delete element">
                    <DeleteOutlined
                      onClick={() => {
                        setToCancel([record]);
                        setShowModal(true);
                      }}
                    />
                  </Tooltip>
                )}
                {customActions && customActions?.length > 0 && customActions.map((child) => child(record))}
              </Space>
            ),
          } as ColumnType<T>)
        : {},
    ];

    const onTableChange = useCallback(
      (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<T> | SorterResult<T>[]
      ) => {
        const filtersStr = colsDataIndexs(cols)
          .map((index) => {
            if (filters[index.dataIndex] !== null) {
              const filterValue = filters[index.dataIndex];
              if (filterValue) {
                switch (index.types) {
                  case ColumnsDataType.string:
                    const strValue = filterValue[0] as unknown as string;
                    if (strValue.startsWith(".")) return `${index.dataIndex}${filterValue}`;
                    return `${index.dataIndex}.Contains('${filterValue}')`;
                  case ColumnsDataType.boolean:
                  case ColumnsDataType.enumerator:
                    return filterValue?.map((f) => `${index.dataIndex} = ${f}`).join(" OR ");
                  case ColumnsDataType.number:
                    return `${index.dataIndex} = ${filterValue}`;
                  default:
                    return "";
                }
              }
            }
            return "";
          })
          .filter((s) => s !== "");

        let sortStr: string | undefined = "";
        if ((sorter as SorterResult<T>[]).length > 1) {
          sortStr = (sorter as SorterResult<T>[])
            .map((sort) => `${sort.field} ${sort.order === "ascend" ? "asc" : "desc"}`)
            .join(",");
        } else {
          const sort = sorter as SorterResult<T>;
          const order = sort.order === "ascend" ? "asc" : "desc";
          sortStr = sort && sort.field ? `${sort.field} ${order}` : undefined;
        }

        getData(filtersStr.join(" AND "), sortStr, pagination.current, pagination.pageSize);
      },
      // eslint-disable-next-line
      []
    );

    /** Deleting Row - action and modal confirmation handler */
    const deleteAction = (id: string, hash: number) => {
      setLoading(true);
      (dal as IDalCRUDSchema<T>).delete(id, hash).then((result) => {
        if ((result as ServerError).errorCode) {
          openNotificationWithIcon("error", "Delete error", (result as ServerError).userMessage);
        } else if (result) {
          openNotificationWithIcon("success", "Operazione eseguita", "Elemento cancellato con successo!");
        }
        setLoading(false);
        getData(filters, sorters, page, pageSize);
      });
    };

    const handleOk = () => {
      if (toCancel && toCancel.length === 1) deleteAction(toCancel[0]._id, Number(toCancel[0].hash));
      setShowModal(false);
    };

    const handleCancel = () => {
      setShowModal(false);
    };

    /** Import and Export to server entity data */
    const handleExport = (type: ExportingDataType = "csv") => {
      try {
        setLoading(true);
        (dal as IDalCRUDSchemaWithExport<T>)
          .exportData({ type, filter: filters, orderBy: sorters })
          .then(() => setLoading(false))
          .catch((error) => console.error(JSON.stringify(error)));
      } catch (error) {}
    };

    const beforeUpload = useCallback((file: RcFile) => {
      try {
        const reader = new FileReader();
        reader.onload = (e: any) =>
          setImportData(
            JSON.parse(
              e.target.result
                .toString("utf8")
                .replace(/^\uFFFD/, "")
                .replace(/\0/g, "")
            )
          );
        reader.readAsText(file);
      } catch (ex) {
        openNotificationWithIcon("error", "Errore caricamento", "Errore nella lettura del file...");
      }

      // disable auto upload by returning false
      return false;
    }, []);

    useEffect(() => {
      if (importData && importData.length > 0) {
        setUploading(true);
        (dal as IDalCRUDBulkSchema<T>)
          .bulkCreate(importData)
          .then((result) => {
            if (!(result as ServerError).httpStatusCode)
              openNotificationWithIcon("info", "Import finish", "All data added to Database");
            else {
              openNotificationWithIcon("info", "Import finish", "The server could not perform the requested operation");
              console.debug(JSON.stringify(result));
            }
            setUploading(false);
            setImportData(undefined);
          })
          .catch((error) => {
            openNotificationWithIcon("error", "Error", JSON.stringify(error));
            console.error(JSON.stringify(error));
            setUploading(false);
            setImportData(undefined);
          });
      }
    }, [importData]);

    return (
      <>
        {canExport && (
          <>
            <div style={{ textAlign: "right", width: "100%", marginBottom: 15, float: "right" }}>
              <Upload accept="json" showUploadList={false} multiple={false} maxCount={1} beforeUpload={beforeUpload}>
                <Button type="link" icon={<UploadOutlined />}>
                  {uploading ? "Uploading" : "Import from JSON"}
                </Button>
              </Upload>
              {uploading && <Loading />}
            </div>
          </>
        )}
        {filters && sorters && (
          <div style={{ textAlign: "center", marginBottom: 5, width: "100%", clear: "both" }}>
            <span>
              <i>Filters enabled on table!</i>
            </span>
          </div>
        )}
        {addEnabled && (
          <div style={{ textAlign: "right", marginBottom: 5, float: "right" }}>
            {canExport && (
              <>
                <Button type="link" icon={<ExportOutlined />} onClick={() => handleExport("csv")}>
                  Export Table - CSV
                </Button>
                <Button type="link" icon={<ExportOutlined />} onClick={() => handleExport("json")}>
                  Export Table - JSON
                </Button>
              </>
            )}
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => history.push(`/${formUrl}`, { tableData: { filters, sorters, page, pageSize } })}
            >
              Add
            </Button>
          </div>
        )}
        <div style={{ textAlign: "right", marginBottom: 5, float: "left" }}>
          <Button type="link" icon={<ReloadOutlined />} onClick={() => getData(undefined, undefined, 1, 10)}>
            Reset filters
          </Button>
          {filters && <span style={{ color: "darkcyan" }}>One or more filters are set</span>}
        </div>
        {selectionType && (
          <div style={{ textAlign: "right", marginBottom: 5 }}>
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => props.onSelectionCallback && props.onSelectionCallback(selectedRow)}
            >
              Select
            </Button>
          </div>
        )}
        <Table
          columns={colsWithFiltersAndActions}
          rowKey={(record) => record._id}
          dataSource={data || []}
          loading={loading}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: totalRecords,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          scroll={{ x: 800 }}
          onChange={onTableChange}
          title={tableTitle}
          footer={tableFooter}
          expandable={{ ...props.expandable }}
          className={props.className}
          rowSelection={
            selectionType && {
              onChange: (_selectedRowsKey, selectedRow) => {
                setSelectedRow(selectedRow);
              },
              type: selectionType,
            }
          }
        />
        <Modal title="Confirm delete" visible={showModal} onOk={handleOk} onCancel={handleCancel}>
          <p>Are you sure to cancel the selected row?</p>
        </Modal>
      </>
    );
  };
};
