import { ColumnType } from "antd/lib/table";
import { RowSelectionType } from "antd/lib/table/interface";
import { ExpandableConfig } from "rc-table/lib/interface";
import React from "react";
import { IDalCRUDSchema, IDalCRUDSchemaWithExport, IDalR } from "../dalFactory";

export enum ColumnsDataType {
  string = "string",
  number = "number",
  date = "date",
  boolean = "boolean",
  enumerator = "enumerator",
}

export interface ITableColumn<T> extends ColumnType<T> {
  dataType?: ColumnsDataType;
}

export interface ILocationState<T> {
  record?: T;
  tableData?: { filters?: string; sorters?: string; page: number; pageSize: number };
}

export interface ITableReadOnlyProps<T> {
  data?: T[];
  className?: string;
}

export interface ITableCrudProps<T> {
  canExport?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  expandable?: ExpandableConfig<T>;
  className?: string;
  customActions?: ((record: T) => React.ReactNode)[];
  onSelectionCallback?: (selected?: T[]) => void;
}

export interface ITableCrudFactoryOptions<T> {
  dal: IDalR<T> | IDalCRUDSchema<T> | IDalCRUDSchemaWithExport<T>;
  dalR?: IDalR<T>;
  cols: ITableColumn<T>[];
  formUrl?: string;
  tableTitle?: () => React.ReactNode;
  tableFooter?: () => React.ReactNode;
  editEnabled?: boolean;
  deleteEnabled?: boolean;
  addEnabled?: boolean;
  selectionType?: RowSelectionType;
  tableId: string;
  useTableDataForEditing: boolean;
}
