import { ReduxStoreSingleton } from '../reduxStore';
import { ThunkDispatch } from 'redux-thunk';

/**
 * It provides a way to dispatch all request needed to load system tables that are mandatory to handle requests
 * @param dispatch Redux dispatcher instance
 */
export const reduxStorePreloader = {
  /**
   * It load all reducers needed by application
   * NOTE: PUT HERE ALL LOADERS TO FETCH AT FIRST LOADING OF APP
   * AND REMEMBER TO PUT IT ON "src\reducers\persistStoreConfiguration.ts"
   * TO PERSIST IT
   * @param  {Dispatch} dispatch
   */
  load: async (dispatch: ThunkDispatch<any, any, any>) => {
    const ReduxStore = await ReduxStoreSingleton.getInstance();
    const appStore = ReduxStore.store && ReduxStore.store.getState();
    if (appStore === undefined) {
      return;
    }

    /*if (appStore.zone.rows.length === 0) {
      dispatch(tabelleZonaActions.getAll());
    }*/

    /* PUT HERE OTHERS LOADERS */
  },
};
