import { FC } from "react";
import { cols } from "./POIs.constants";
import { poisDal } from "./POIs.dal";
import { entityTableCRUDFactory, ITableCrudProps } from "../../hoc/TableFactory";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { PointOfInterest } from "../../common/interfaces";
import { Roles } from "../../common/constants";
import { useAdministrativePage } from "../../common/customHooks/useAdministrativePage";
import { useReadOnlyPage } from "../../common/customHooks/useReadOnlyPage";

const PoisTable: FC<ITableCrudProps<PointOfInterest>> = entityTableCRUDFactory({
  tableId: "pois",
  dal: poisDal,
  cols,
  formUrl: "poi",
  addEnabled: true,
  editEnabled: true,
  deleteEnabled: true,
  useTableDataForEditing: true,
});

export const POIs = () => {
  useAdministrativePage();
  const readOnly = useReadOnlyPage([Roles.Staff]);

  return (
    <FlexContainer>
      <FlexPanelMiddle title={"Points of Interest Registry"} panelClassName="md-card-3">
        <PoisTable canExport={!readOnly} canDelete={!readOnly} canEdit={!readOnly} />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
