import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import itIT from 'antd/lib/locale-provider/it_IT';
import reportWebVitals from './reportWebVitals';
import { ReduxStoreSingleton } from './common/redux/reduxStore';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';

const renderReactDom = (locale: any, persistorDom: any, storeProvider: any) => {
  ReactDOM.render(
    <Provider store={storeProvider}>
      <ConfigProvider locale={locale}>
        <PersistGate loading={null} persistor={persistorDom}>
          <App />
        </PersistGate>
      </ConfigProvider>
    </Provider>,
    document.getElementById('root')
  );
};

ReduxStoreSingleton.getInstance().then(({ store, persistor }) => {
  renderReactDom(itIT, persistor, store);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
