import { FC } from "react";
import { IPanelHeadProps, IPanelProps } from "./Panel.interfaces";

export const PanelBody: FC = (props) => {
  return <div className="panel-body">{props.children}</div>;
};

export const PanelHead: FC<IPanelHeadProps> = (props) => {
  const { title, children } = props;
  return (
    <div className="panel-head">
      {title && (
        <div className="panel-head-content-div">
          <span className="panel-title">{title}</span>
        </div>
      )}
      {children}
    </div>
  );
};

export const Panel: FC<IPanelProps> = (props) => {
  const { panelClassName } = props;
  return <div className={`panel ${panelClassName ? panelClassName : "md-card-3"}`}>{props.children}</div>;
};
