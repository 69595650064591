import { FC, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { authProvider } from "../../common/authProvider";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { useMountEffect } from "../../common/customHooks/useMountEffect";
import { UserCountResult } from "../../common/interfaces";
import { userCount, userLoginCount } from "./home.dal";
import { VictoryChart, VictoryAxis, VictoryTheme, VictoryLine, VictoryLabel, VictoryBar } from "victory";

const { useAuth } = authProvider;

const userCountResultSorter = (a: UserCountResult, b: UserCountResult) => {
  if (!a.groupId || !b.groupId) return 0;
  if (a.groupId > b.groupId) return 1;
  if (a.groupId < b.groupId) return -1;
  return 0;
};

export const Home: FC = () => {
  const [isLogged, userData] = useAuth();
  const [totalUser, setTotalUser] = useState<number>();
  const [totalUserFromFb, setTotalUserFromFb] = useState<number>();
  const [totalUserFromApple, setTotalUserFromApple] = useState<number>();
  const [totalUserFromLocal, setTotalUserFromLocal] = useState<number>();
  const [listUserPerData, setListUserPerData] = useState<UserCountResult[]>();
  const [listUserPerRole, setListUserPerRole] = useState<UserCountResult[]>();
  const [listLoginPerData, setListLoginPerData] = useState<UserCountResult[]>();
  const history = useHistory();

  const goToLogin = useCallback(() => history.push("login"), [history]);

  useEffect(() => {
    if (!isLogged) goToLogin();
  }, [isLogged, goToLogin]);

  useMountEffect(() => {
    userCount().then((res) => {
      res.data && res.data.length === 1 && setTotalUser(res.data[0].count);
    });

    userCount({ origin: "fb" }).then((res) => {
      if (res.data && res.data.length === 1) setTotalUserFromFb(res.data[0].count);
      else setTotalUserFromFb(0);
    });

    userCount({ origin: "apple" }).then((res) => {
      res.data && res.data.length === 1 ? setTotalUserFromApple(res.data[0].count) : setTotalUserFromApple(0);
    });

    userCount({ origin: "local" }).then((res) => {
      res.data && res.data.length === 1 ? setTotalUserFromLocal(res.data[0].count) : setTotalUserFromLocal(0);
    });

    const today = new Date();
    today.setDate(today.getDate() - 14);
    userCount({
      groupBy: "registeredOn",
      fromDate: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
    }).then((res) => {
      res.data && res.data.length >= 1
        ? setListUserPerData(res.data.sort(userCountResultSorter))
        : setListUserPerData([]);
    });

    userLoginCount({
      fromDate: `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
    }).then((res) => {
      res.data && res.data.length >= 1
        ? setListLoginPerData(res.data.sort(userCountResultSorter))
        : setListLoginPerData([]);
    });

    userCount({ groupBy: "role" }).then((res) => {
      res.data && res.data.length >= 1 ? setListUserPerRole(res.data) : setListUserPerRole([]);
    });
  });

  return (
    <FlexContainer>
      <FlexPanelMiddle title={`Benvenuto ${userData?.email || "admin"}`} panelClassName="md-card-3">
        <p>Benvenuto su NaTourWine.</p>
        {(userData?.role === "Admin" || userData?.role === "SuperAdmin") && (
          <>
            <p>Numero utenti totali su database: {totalUser}</p>
            <div style={{ width: "50%", float: "left" }}>
              <p>Numero degli utenti raggruppati per provenienza:</p>
              <ul>
                <li>da Facebook = {totalUserFromFb}</li>
                <li>da Apple = {totalUserFromApple}</li>
                <li>registrazione locale = {totalUserFromLocal}</li>
              </ul>
            </div>
            <div style={{ width: "50%", float: "right" }}>
              <p>Numero degli utenti raggruppati per ruolo:</p>
              {listUserPerRole && (
                <ul>
                  {listUserPerRole.map((u) => (
                    <li>
                      {u.groupId} = {u.count}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {listUserPerData && listLoginPerData && (
              <>
                <div style={{ width: "50%", float: "left" }}>
                  <VictoryChart theme={VictoryTheme.material} domainPadding={20}>
                    <VictoryAxis
                      style={{
                        tickLabels: { fontSize: 9, angle: -45, textAnchor: "end" },
                      }}
                      tickValues={listUserPerData.map((l) => l.groupId)}
                    />
                    <VictoryAxis
                      dependentAxis
                      style={{
                        tickLabels: { fontSize: 12, padding: 5, angle: 0 },
                      }}
                    />
                    <VictoryLine
                      style={{
                        data: {
                          stroke: "#c43a31",
                        },
                        labels: {
                          fontSize: 9,
                          textAnchor: "start",
                        },
                      }}
                      data={listUserPerData}
                      x="groupId"
                      y="count"
                      labels={({ datum }) => datum.count}
                      labelComponent={<VictoryLabel renderInPortal />}
                    />
                  </VictoryChart>
                </div>
                <div style={{ width: "50%", float: "right" }}>
                  <VictoryChart theme={VictoryTheme.material} domainPadding={30}>
                    <VictoryAxis
                      style={{
                        tickLabels: { fontSize: 9, angle: -45, textAnchor: "end" },
                      }}
                      tickValues={listLoginPerData.map((l) => l.groupId)}
                    />
                    <VictoryAxis
                      dependentAxis
                      style={{
                        tickLabels: { fontSize: 12, padding: 5, angle: 0 },
                      }}
                    />
                    <VictoryBar
                      style={{
                        data: {
                          stroke: "#c43a31",
                        },
                        labels: {
                          fontSize: 9,
                          textAnchor: "start",
                        },
                      }}
                      data={listLoginPerData}
                      x="groupId"
                      y="count"
                      labels={({ datum }) => datum.count}
                      labelComponent={<VictoryLabel renderInPortal />}
                    />
                  </VictoryChart>
                </div>
              </>
            )}
          </>
        )}
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
