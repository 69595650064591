import { LoginApiInputData, LoginApiOutputData } from '../../common/interfaces/web-api';
import Axios, { AxiosResponse } from 'axios';
import { webApiBaseUrl } from '../../common/constants';

export const LoginDal = {
  login: async (data: LoginApiInputData) => {
    try {
      return (await Axios.post<LoginApiInputData, AxiosResponse<LoginApiOutputData>>(`${webApiBaseUrl}/auth/login`, data)).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};
