import { IAuthState } from "../interfaces/auth.interfaces";
import { initialLoadingStatus } from "../reduxStore.constants";
import { Entities } from "../../constants";
import { CommonConstants } from "./common.constants";

export const AuthConstants = {
  Login: `${Entities.Auth}_Login`,
  Logout: `${Entities.Auth}_Logout`,
  ...CommonConstants(Entities.Auth),
};

export const initialState: IAuthState = {
  requestStatus: { ...initialLoadingStatus },
};
