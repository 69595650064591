import { useEffect, useState } from "react";

const getItemFromLocalStorage = <T>(key: string): T | undefined => {
  const { value } = JSON.parse(localStorage.getItem(key) || JSON.stringify({}));
  return value;
};

const setItemToLocalStorage = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify({ value }));
};

export const useStateWithLocalStorage = <T>(
  localStorageKey: string
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>] => {
  const [value, setValue] = useState<T | undefined>(getItemFromLocalStorage(localStorageKey));

  useEffect(() => {
    setItemToLocalStorage(localStorageKey, value);
  }, [value, localStorageKey]);

  return [value, setValue];
};
