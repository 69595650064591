import { Empty, Radio } from "antd";
import { FC, useEffect, useState } from "react";
import { DesignMap } from "../../common/components/DesignMap/DesignMap";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelLeft } from "../../common/components/FlexPanelLeft";
import { FlexPanelRight } from "../../common/components/FlexPanelRight";
import { Panel, PanelBody, PanelHead } from "../../common/components/Panel";
import { Asset, Winery } from "../../common/interfaces";
import { IJsonFormProps, jsonFormFactory } from "../../hoc/FormFactory";
import { markerDal, wineriesDal } from "./Wineries.dal";

const WineryJsonFormComponent: FC<IJsonFormProps> = jsonFormFactory(wineriesDal, "wineries");

export const WineryForm: FC<{ match: any }> = (props) => {
  const [formData, setFormData] = useState<Winery>();
  const [assetMarker, setAssetMarker] = useState<Asset>();
  const [mapsType, setMapsType] = useState<"world" | "street">("street");

  useEffect(() => {
    if (formData && formData.region) {
      markerDal.get({ filter: `name.Contains('${formData.region.split("'")[0]}')` }).then((result) => {
        if (result && result.data && result.data.data) setAssetMarker(result.data.data[0]);
      });
    }
  }, [formData]);

  const onChange = (e: any) => {
    if (e.target.value) setMapsType(e.target.value);
  };

  return (
    <FlexContainer>
      <FlexPanelLeft size="half">
        <Panel>
          <PanelHead title="Logo" />
          <PanelBody>
            <div style={{ textAlign: "center" }}>
              {formData && formData.logo ? <img src={`${formData.logo}`} alt="no data" /> : <Empty />}
            </div>
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHead title="Marker" />
          <PanelBody>
            <div style={{ textAlign: "center" }}>
              {formData && formData.region && assetMarker ? (
                <div style={{ textAlign: "center", maxHeight: 64 }}>
                  <img style={{ maxHeight: 64 }} src={`${assetMarker.base64Data}`} alt="no data" />
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </PanelBody>
        </Panel>
        <Panel>
          <PanelHead title="Position" />
          <PanelBody>
            <div style={{ clear: "both", width: "100%" }}>
              <Radio.Group onChange={onChange} value={mapsType}>
                <Radio value={"world"}>World</Radio>
                <Radio value={"street"}>Street</Radio>
              </Radio.Group>
            </div>
            <div style={{ textAlign: "center", maxHeight: 450 }}>
              {formData && formData.location && formData.location.latitude && formData.location.longitude ? (
                <DesignMap
                  mapsType={mapsType}
                  mapsHeight={420}
                  centerPosition={{ lat: formData.location.latitude, lng: formData.location.longitude }}
                />
              ) : (
                <Empty />
              )}
            </div>
          </PanelBody>
        </Panel>
      </FlexPanelLeft>
      <FlexPanelRight size="half">
        <Panel>
          <PanelHead title="Winery data" />
          <PanelBody>
            <WineryJsonFormComponent {...props} onChangeFormData={(data: Winery) => setFormData(data)} />
          </PanelBody>
        </Panel>
      </FlexPanelRight>
    </FlexContainer>
  );
};
