import { useState, useEffect } from "react";
import { authProvider } from "../authProvider";
import { Roles } from "../constants";

const { useAuth } = authProvider;

export const useReadOnlyPage = (readOnlyRoles: Roles[]) => {
  const [, userData] = useAuth();
  const [readOnly, setReadOnly] = useState<boolean>();

  useEffect(() => {
    if (userData) {
      if (readOnlyRoles.find((s) => s === userData.role)) setReadOnly(true);
      else setReadOnly(false);
    }
  }, [userData, readOnlyRoles]);

  return readOnly;
};
