import { Winery, WineryDataOutputData, WineryType } from "../../common/interfaces";
import { nameof } from "../../utils";
import {
  booleanFilterColFactory,
  ColumnsDataType,
  enumeratorFilterColFactory,
  ITableColumn,
  renderBooleanDataFunction,
  renderDateStringFunction,
  renderEnumDataColsFactory,
} from "../../hoc/TableFactory";
import { EntityStatus } from "../../common/constants";

export const cols = [
  {
    title: "Logo",
    dataIndex: nameof<WineryDataOutputData>("hasLogo"),
    key: nameof<WineryDataOutputData>("hasLogo"),
    dataType: ColumnsDataType.boolean,
    render: renderBooleanDataFunction,
    filters: booleanFilterColFactory(),
    responsive: ["xxl"],
  },
  // {
  //   title: "Logo preview",
  //   dataIndex: nameof<Winery>("logo"),
  //   key: nameof<Winery>("logo"),
  //   render: (value) => {
  //     return (
  //       <>
  //         {value && (
  //           <div style={{ textAlign: "center", maxWidth: 64 }}>
  //             <img src={value} style={{ width: 64 }} alt="no data" />
  //           </div>
  //         )}
  //         {!value && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} style={{ width: 64 }} imageStyle={{ maxHeight: 32, maxWidth: 64 }} />}
  //       </>
  //     );
  //   },
  //   responsive: ["xl"],
  // },
  {
    title: "Type",
    dataIndex: nameof<Winery>("type"),
    key: nameof<Winery>("type"),
    dataType: ColumnsDataType.enumerator,
    render: renderEnumDataColsFactory(WineryType, "_"),
    filters: enumeratorFilterColFactory(WineryType),
  },
  {
    title: "Name",
    dataIndex: nameof<Winery>("name"),
    key: nameof<Winery>("name"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Name 2",
    dataIndex: nameof<Winery>("name2"),
    key: nameof<Winery>("name2"),
    dataType: ColumnsDataType.string,
    sorter: true,
    responsive: ["xl"],
  },
  {
    title: "Vigneron",
    dataIndex: nameof<Winery>("vigneron"),
    key: nameof<Winery>("vigneron"),
    dataType: ColumnsDataType.string,
    sorter: true,
    responsive: ["xl"],
  },
  {
    title: "Short Name 1",
    dataIndex: nameof<Winery>("subName1"),
    key: nameof<Winery>("subName1"),
    dataType: ColumnsDataType.string,
    sorter: true,
    responsive: ["xxl"],
  },
  {
    title: "Short Name 2",
    dataIndex: nameof<Winery>("subName2"),
    key: nameof<Winery>("subName2"),
    dataType: ColumnsDataType.string,
    sorter: true,
    responsive: ["xxl"],
  },
  {
    title: "City",
    dataIndex: nameof<Winery>("city"),
    key: nameof<Winery>("city"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Province",
    dataIndex: nameof<Winery>("province"),
    key: nameof<Winery>("province"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Region",
    dataIndex: nameof<Winery>("region"),
    key: nameof<Winery>("region"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Last update",
    dataIndex: nameof<Winery>("lastUpdate"),
    key: nameof<Winery>("lastUpdate"),
    dataType: ColumnsDataType.date,
    render: renderDateStringFunction,
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: nameof<Winery>("status"),
    key: nameof<Winery>("status"),
    dataType: ColumnsDataType.enumerator,
    render: renderEnumDataColsFactory(EntityStatus, "_"),
    filters: enumeratorFilterColFactory(EntityStatus),
    responsive: ["xxl"],
  },
] as ITableColumn<Winery>[];
