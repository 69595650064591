import React, { FC, useEffect, useState } from "react";
import { Layout } from "antd";
import { LeftNavBar } from "./LeftNavBar";
import { PrivateRouter } from "../PrivateRouter";
import { RightNavBar } from "./RightNavBar";
import { menuJsonDataBuilder } from "./leftNavBar.constats";
import { useDispatch } from "react-redux";
import { authProvider } from "../../common/authProvider";
import { useHistory } from "react-router";
import { LoginHistory } from "../../common/interfaces";
import { reduxStorePreloader } from "../../common/redux/actions/reduxStorePreloader.actions";
// import { useMountEffect } from "../../common/customHooks/useMountEffect";
import { openNotificationWithIcon } from "../../common/components/Notification";
import { Roles } from "../../common/constants";
import { ILeftMenuItem } from "./leftNavBar.interfaces";

const { useAuth } = authProvider;

export const UiShell: FC = () => {
  const [isLogged, userData] = useAuth();
  const [menuJsonData, setMenuJsonData] = useState<ILeftMenuItem[]>([]);
  const history = useHistory<LoginHistory>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData && (userData.role === Roles.User || userData.exp * 1000 < Date.now())) {
      openNotificationWithIcon(
        "error",
        "Access Denied",
        "Access denied, your token is expired or you  don't have administrative privilegies."
      );
      authProvider.logout();
    }
    if (userData && userData.role) setMenuJsonData(menuJsonDataBuilder(userData.role));
  }, [userData]);

  useEffect(() => {
    reduxStorePreloader.load(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (history.location.state) {
      const { state } = history.location;
      if (state.fromLogin) history.push("/");
    }
  }, [history]);

  useEffect(() => {
    if (!isLogged) history.push("/login");
  }, [isLogged, history]);

  return (
    <div>
      <Layout style={{ height: "100vh" }}>
        <LeftNavBar menuJsonData={menuJsonData} />
        <Layout style={{ minHeight: "100vh" }}>
          <PrivateRouter />
        </Layout>
        <RightNavBar notifications={[]} notificationsNotRead={[]} />
      </Layout>
    </div>
  );
};
