import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { entityTableCRUDFactory } from "../../hoc/TableFactory";
import { cols } from "./Assets.constants";
import { markerDal } from "./Asset.dal";
import { Roles } from "../../common/constants";
import { useReadOnlyPage } from "../../common/customHooks/useReadOnlyPage";
import { useAdministrativePage } from "../../common/customHooks/useAdministrativePage";

const AssetTable = entityTableCRUDFactory({
  tableId: "assets",
  dal: markerDal,
  cols,
  formUrl: "asset",
  addEnabled: true,
  editEnabled: true,
  deleteEnabled: true,
  useTableDataForEditing: true,
});

export const Assets = () => {
  useAdministrativePage();
  const readOnly = useReadOnlyPage([Roles.Staff]);

  return (
    <FlexContainer>
      <FlexPanelMiddle title={"Asset Registry"} panelClassName="md-card-3">
        <AssetTable canExport={false} canDelete={!readOnly} canEdit={!readOnly} />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
