import { Entities } from "../../common/constants";
import { entityDalFactory, GenericDalOperation, IDalCRUDSchema, IDalCRUDSchemaWithExport } from "../../hoc/dalFactory";
import { Journey, JourneyStage } from "../../common/interfaces";

export const journeysDal = entityDalFactory<Journey, IDalCRUDSchemaWithExport<Journey>>({
  enableOperation: GenericDalOperation.CRUD,
  entityName: Entities.Journeys,
  enableSchema: true,
  enableExport: true,
});

export const journeyStagesDalBuilder = (id: string) =>
  entityDalFactory<JourneyStage, IDalCRUDSchema<JourneyStage>>({
    enableOperation: GenericDalOperation.CRUD,
    entityName: `${Entities.Journeys}/${id}/${Entities.Stages}`,
    enableSchema: true,
  });
