import { ILeftMenuItem } from "./leftNavBar.interfaces";
import {
  HomeOutlined,
  BugOutlined,
  UserOutlined,
  FullscreenExitOutlined,
  BookOutlined,
  GlobalOutlined,
  CompassOutlined,
  PictureOutlined,
  EnvironmentOutlined,
  RetweetOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Roles } from "../../common/constants";

const HomeMenuLinkBuilder: (id: number) => ILeftMenuItem = (id: number) => ({
  id,
  title: "Home",
  routing: "/",
  icon: <HomeOutlined />,
  parentid: null,
});

const FormSchemaEntryMenuBuilder: (id: number) => ILeftMenuItem = (id: number) => ({
  id,
  title: "Form Schema",
  routing: "/form-schemas",
  icon: <FormOutlined />,
  parentid: null,
});

const RegistryMenuLinkBuilder: (startingId: number) => ILeftMenuItem[] = (startingId: number) => [
  {
    id: startingId,
    title: "Registry",
    routing: "/",
    icon: <BookOutlined />,
    parentid: null,
  },
  {
    id: startingId + 1,
    title: "Users",
    routing: "/users",
    icon: <UserOutlined />,
    parentid: startingId,
  },
  {
    id: startingId + 2,
    title: "Wineries",
    routing: "/wineries",
    icon: <BugOutlined />,
    parentid: startingId,
  },
  {
    id: startingId + 3,
    title: "POIs",
    routing: "/pois",
    icon: <FullscreenExitOutlined />,
    parentid: startingId,
  },
  {
    id: startingId + 4,
    title: "Journeys",
    routing: "/journeys",
    icon: <GlobalOutlined />,
    parentid: startingId,
  },
  {
    id: startingId + 5,
    title: "Assets",
    routing: "/assets",
    icon: <PictureOutlined />,
    parentid: startingId,
  },
];

const AppPreviewMenuLinkBuilder: (startingId: number) => ILeftMenuItem[] = (startingId) => [
  {
    id: startingId,
    title: "App preview",
    routing: "/",
    icon: <CompassOutlined />,
    parentid: null,
  },
  {
    id: startingId + 1,
    title: "Wineries Map",
    routing: "/map/wineries",
    icon: <EnvironmentOutlined />,
    parentid: startingId,
  },
  {
    id: startingId + 2,
    title: "Journeys Map",
    routing: "/map/journeys",
    icon: <RetweetOutlined />,
    parentid: startingId,
  },
];

export const menuJsonDataBuilder = (role: string) => {
  switch (role) {
    case Roles.SuperAdmin:
      return [HomeMenuLinkBuilder(10), FormSchemaEntryMenuBuilder(15), ...RegistryMenuLinkBuilder(20), ...AppPreviewMenuLinkBuilder(30)];
    case Roles.Admin:
    case Roles.Staff:
      return [HomeMenuLinkBuilder(10), ...RegistryMenuLinkBuilder(20), ...AppPreviewMenuLinkBuilder(30)];
    case Roles.PowerUser:
    case Roles.User:
      return [HomeMenuLinkBuilder(10), ...AppPreviewMenuLinkBuilder(20)];
    default:
      return [HomeMenuLinkBuilder(10)];
  }
};
