import { FC, useState } from "react";
import { poisDal } from "./POIs.dal";
import { IJsonFormProps, jsonFormFactory } from "../../hoc/FormFactory";
import { FlexContainer } from "../../common/components/FlexContainer";
import { PointOfInterest } from "../../common/interfaces";
import { Panel, PanelBody, PanelHead } from "../../common/components/Panel";
import { FlexPanelRight } from "../../common/components/FlexPanelRight";
import { DesignMap } from "../../common/components/DesignMap/DesignMap";
import { Empty } from "antd";
import { FlexPanelLeft } from "../../common/components/FlexPanelLeft";

const PoiForm: FC<IJsonFormProps> = jsonFormFactory(poisDal, "pois");

export const Poi: FC<{ match: any }> = (props) => {
  const title = props.match?.params?.id ? "Modify Point of interest data" : "Add new Point of interest";
  const [formData, setFormData] = useState<PointOfInterest>();

  return (
    <FlexContainer>
      <FlexPanelLeft size="half">
        <Panel>
          <PanelHead title="Position" />
          <PanelBody>
            <div style={{ textAlign: "center" }}>
              {formData && formData.location && formData.location.latitude && formData.location.longitude ? (
                <DesignMap centerPosition={{ lat: formData.location.latitude, lng: formData.location.longitude }} />
              ) : (
                <Empty />
              )}
            </div>
          </PanelBody>
        </Panel>
      </FlexPanelLeft>
      <FlexPanelRight size="half">
        <Panel>
          <PanelHead title={title} />
          <PanelBody>
            <PoiForm {...props} onChangeFormData={(data: PointOfInterest) => setFormData(data)} />
          </PanelBody>
        </Panel>
      </FlexPanelRight>
    </FlexContainer>
  );
};
