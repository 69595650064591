export const initialLoadingStatus = {
  isLoading: false,
  isError: false,
  isFinish: false,
};

export enum appReduxConstants {
  RESET_REDUX = 'RESET_REDUX',
}

export enum GlobalReduxConstants {
  History_Stack_Size = 10,
}
