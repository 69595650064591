import { WidgetProps } from "@rjsf/core";
import { Checkbox, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useMountEffect } from "../../common/customHooks/useMountEffect";

export const multipleEnumWidgetFactory = (enumType: any) => {
  return function MultipleEnumWidget(props: WidgetProps) {
    const [selectedValues, setSelectedValues] = useState<number[]>([]);
    const [enumArray, setEnumArray] = useState<{ descr: string; value: number }[]>([]);
    const { value } = props;

    useMountEffect(() => {
      const enumValues = Object.values(enumType);
      const descr = enumValues.filter((v) => isNaN(Number(v))) as string[];
      const numb = enumValues.filter((v) => !isNaN(Number(v))) as number[];

      const array: { descr: string; value: number }[] = [];
      for (let i = 0; i < descr.length; i++) {
        array.push({ descr: descr[i], value: numb[i] });
      }
      setEnumArray(array);
    });

    useEffect(() => {
      const shouldBeChecked = (val: number) => {
        return (value & val) > 0;
      };

      const valuesArray = enumArray
        .map((a) => {
          if (shouldBeChecked(a.value)) return a.value;
          return undefined;
        })
        .filter((x) => x !== undefined) as number[];

      setSelectedValues(valuesArray);
    }, [value, enumArray]);

    return (
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={(values) => props.onChange(values.reduce((a, b) => Number(a) + Number(b), 0))}
        defaultValue={selectedValues}
        value={selectedValues}
      >
        {enumArray && (
          <Row>
            {enumArray.map((a) => (
              <Col xs={10} sm={8} md={6} lg={4} xl={4}>
                <Checkbox key={`${a.value}-${a.descr}`} value={a.value}>
                  {a.descr}
                </Checkbox>
              </Col>
            ))}
          </Row>
        )}
      </Checkbox.Group>
    );
  };
};
