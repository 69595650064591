import { useEffect, useState } from "react";
import Form, { ISubmitEvent, UiSchema } from "@rjsf/core";
import { useHistory, useLocation } from "react-router";
import { ILocationState } from "../TableFactory";
import { JSONSchema7 } from "json-schema";
import { useMountEffect } from "../../common/customHooks/useMountEffect";
import { IDalCRUDSchema, ServerError } from "../dalFactory";
import { openNotificationWithIcon } from "../../common/components/Notification";
import { Loading } from "../../common/components/Loading";
import { AssociationWidget, ExtraServicesWidget, LookupWidget, TimePickerWidget, VersionWidget } from "./customWidget";
import { IJsonFormProps } from "./FormFactory.interfaces";
import { cleanObject } from "../../utils/cleanObject";

const customWidgets = {
  association: AssociationWidget,
  extraServices: ExtraServicesWidget,
  time: TimePickerWidget,
  lookup: LookupWidget,
  version: VersionWidget,
};

export const jsonFormFactory = <T extends { _id: string; hash?: number }>(dal: IDalCRUDSchema<T>, gridUrl: string) => {
  return function JsonEntityForm(props: IJsonFormProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [formSchema, setSchema] = useState<JSONSchema7>();
    const [uiSchema, setUiSchema] = useState<UiSchema>();
    const [formData, setFormData] = useState<T>();
    const history = useHistory<ILocationState<T>>();
    const location = useLocation<ILocationState<T>>();
    const { record } = location.state || {};
    const { schemaUrl, onChangeFormData, match } = props;
    const id = match?.params?.id || undefined;

    useMountEffect(() => {
      setLoading(true);
      dal
        .schema(schemaUrl)
        .then((result) => {
          let isFinished = false;
          if (result && result.data) {
            const { schema, uiSchema } = result.data;
            const jsonSchema = JSON.parse(schema);
            if (jsonSchema.description) jsonSchema.description = undefined;
            if (jsonSchema) setSchema(jsonSchema);
            if (uiSchema) setUiSchema(JSON.parse(uiSchema));
            if (record) {
              cleanObject(record);
              setFormData(record);
              isFinished = true;
            } else if (id) {
              dal
                .getById(id)
                .then((result) => {
                  if (result && result.data) {
                    cleanObject(result.data);
                    setFormData(result.data);
                  }
                  setLoading(false);
                })
                .catch((error) => {
                  console.debug(JSON.stringify(error));
                  setLoading(false);
                });
            } else isFinished = true;
          }
          if (isFinished) setLoading(false);
        })
        .catch((error) => {
          console.debug(JSON.stringify(error));
          setLoading(false);
        });
    });

    useEffect(() => {
      onChangeFormData && onChangeFormData(formData);
    }, [formData, onChangeFormData]);

    const onSubmit = (e: ISubmitEvent<T>) => {
      if (e.errors.length === 0 && e.formData !== formData) {
        setLoading(true);
        if (formData?._id)
          dal
            .update(formData._id, e.formData)
            .then((result) => {
              if (result === true) {
                setFormData(e.formData);
                openNotificationWithIcon("success", "Operazione eseguita", "Elemento salvato con successo!");
              } else {
                openNotificationWithIcon("warning", "Saving error", (result as ServerError).userMessage);
              }
              setLoading(false);
            })
            .catch((err) => {
              openNotificationWithIcon("error", "Saving error", "Server could not update data");
              console.debug(JSON.stringify(err));
              setLoading(false);
            });
        else
          dal
            .create(e.formData)
            .then((result) => {
              if (result && !(result as ServerError).errorCode) {
                setFormData(result as T);
                openNotificationWithIcon("success", "Operazione eseguita", "Elemento salvato con successo!");
                history.push(`${gridUrl}`);
              } else {
                openNotificationWithIcon("error", "Saving error", (result as ServerError).userMessage);
                setLoading(false);
              }
            })
            .catch((err) => {
              openNotificationWithIcon("error", "Saving error", "Server could not update data");
              console.debug(JSON.stringify(err));
              setLoading(false);
            });
      }
    };

    return (
      <>
        <div>
          {formSchema && (
            <Form
              schema={formSchema}
              formData={formData}
              uiSchema={uiSchema}
              widgets={customWidgets}
              onSubmit={onSubmit}
              onChange={(e) => {
                const data = e.formData;
                cleanObject(data);
                setFormData(data);
              }}
            ></Form>
          )}
        </div>
        {loading && <Loading />}
      </>
    );
  };
};
