import { FC } from "react";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { IJsonFormProps, jsonFormFactory } from "../../hoc/FormFactory";
import { journeysDal } from "./Journeys.dal";

const JourneyForm: FC<IJsonFormProps> = jsonFormFactory(journeysDal, "journeys");

export const Journey: FC<{ match: any }> = (props) => {
  const title = props.match?.params?.id ? "Modify Journey data" : "Add new Journey";
  return (
    <FlexContainer>
      <FlexPanelMiddle title={title} panelClassName="md-card-3" backButton>
        <JourneyForm {...props} />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
