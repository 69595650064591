import { Entities } from "../../common/constants";
import { entityDalFactory, GenericDalOperation, IDalCRUDSchema } from "../../hoc/dalFactory";
import { Asset, Winery } from "../../common/interfaces";

export const mapDal = entityDalFactory<Winery, IDalCRUDSchema<Winery>>({
  enableOperation: GenericDalOperation.CRUD,
  entityName: Entities.Wineries,
  enableSchema: true,
});

export const markerDal = entityDalFactory<Asset, IDalCRUDSchema<Asset>>({
  enableOperation: GenericDalOperation.CRUD,
  entityName: Entities.Assets,
  enableSchema: true,
});
