import { Col, Row, Tooltip } from "antd";
import email from "../../../assets/icons/mail_2.png";
import perc from "../../../assets/icons/percorso_2.png";
import telefono from "../../../assets/icons/telefono_2.png";
import web from "../../../assets/icons/web_2.png";
import { PointOfInterest, Winery } from "../../../common/interfaces";

export const WineryPopup = (w: Winery) => (
  <div style={{ width: 301 }}>
    <Row gutter={[8, 12]} style={{ width: "100%" }}>
      <Col span={12}>
        <Row justify="space-between" gutter={[8, 8]}>
          <Col style={{ textAlign: "center", verticalAlign: "center" }}>
            <Tooltip title={w.email}>
              <img src={email} alt="no email" style={{ maxWidth: 48, maxHeight: 48 }} />
            </Tooltip>
          </Col>
          <Col style={{ textAlign: "center", verticalAlign: "center" }}>
            <Tooltip title={w.telephone}>
              <img src={telefono} alt="no tel" style={{ maxWidth: 48, maxHeight: 48 }} />
            </Tooltip>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[8, 8]}>
          <Col style={{ textAlign: "center", verticalAlign: "center" }}>
            <Tooltip title={w.webSite}>
              <img src={web} alt="no web" style={{ maxWidth: 48, maxHeight: 48 }} />
            </Tooltip>
          </Col>
          <Col style={{ textAlign: "center", verticalAlign: "center" }}>
            <Tooltip title="Open on Maps">
              <img
                src={perc}
                alt="no perc"
                style={{ maxWidth: 48, maxHeight: 48 }}
                onClick={() => {
                  if (w.location) {
                    const { latitude, longitude } = w.location;
                    latitude && longitude && window.open("https://maps.google.com?q=" + latitude + "," + longitude);
                  }
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col style={{ textAlign: "center", verticalAlign: "center" }} span={12}>
        <img src={w.logo} alt="no logo" style={{ maxWidth: 120, maxHeight: 120 }} />
      </Col>
    </Row>
    <Row gutter={[8, 24]} style={{ width: "100%" }}>
      <Col>
        <h3>{w.name}</h3>
      </Col>
    </Row>
    {w.vigneron && (
      <Row gutter={[8, 24]} style={{ width: "100%" }}>
        <Col>
          <h4>{w.vigneron}</h4>
        </Col>
      </Row>
    )}
    <Row gutter={[8, 24]} style={{ width: "100%" }}>
      <Col>
        <h4>{w.address}</h4>
      </Col>
    </Row>
    <Row gutter={[8, 24]} style={{ width: "100%" }}>
      <Col>
        <h4>{w.city}</h4>
      </Col>
    </Row>
    <Row gutter={[8, 24]} style={{ width: "100%" }}>
      <Col>
        <h4>{w.province}</h4>
      </Col>
    </Row>
    <Row gutter={[8, 24]}>
      <Col span={18} />
      <Col span={6}>
        <h5>{w.region}</h5>
      </Col>
    </Row>
  </div>
);

export const PoisPopup = (w: PointOfInterest) => {
  return (
    <div style={{ width: 301 }}>
      <Row gutter={[8, 24]} style={{ width: "100%" }}>
        <Col>
          <h3>{w.name}</h3>
        </Col>
      </Row>
      <Row gutter={[8, 24]} style={{ width: "100%" }}>
        <Col>
          <h4>{w.province}</h4>
        </Col>
      </Row>
      <Row gutter={[8, 24]} style={{ width: "100%" }}>
        <Col>
          <h4>{w.city}</h4>
        </Col>
      </Row>
      <Row gutter={[8, 24]}>
        <Col span={18}></Col>
        <Col span={6}>
          <h5>{w.region}</h5>
        </Col>
      </Row>
    </div>
  );
};
