import { FC } from "react";
import { IFlexPanelRightProps } from "./FlexPanelRight.interfaces";

export const FlexPanelRight: FC<IFlexPanelRightProps> = (props) => {
  const { size, children } = props;
  const className = size ? (size === "half" ? "flex-panel-right-half" : "flex-panel-right") : "flex-panel-right";
  return (
    <div className={`flex-panel ${className}`}>
      <div className="panel-container">{children}</div>
    </div>
  );
};
