import * as React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { ILeftNavBarProps, ILeftNavBarState, ILeftMenuItem } from "./leftNavBar.interfaces";
import logo from "../../assets/icons/logo.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

export class LeftNavBar extends React.Component<ILeftNavBarProps, ILeftNavBarState> {
  constructor(props: ILeftNavBarProps) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  componentDidUpdate(prevProps: ILeftNavBarProps) {
    const { menuJsonData } = this.props;
    if (prevProps.menuJsonData !== menuJsonData)
      this.setState({
        treeMenu: menuJsonData ? this.unflatten<ILeftMenuItem>(menuJsonData) : [],
      });
  }

  render() {
    const drawMenuItem = (item: ILeftMenuItem) => {
      const icon = item.icon; // this.getIcon(item.icon);
      if (item.children && item.children.length > 0) {
        return (
          <SubMenu
            key={item.id}
            title={
              <span>
                {" "}
                {icon} <span>{item.title}</span>{" "}
              </span>
            }
          >
            {item.children.map((childItem: any) => drawMenuItem(childItem))}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item key={item.id}>
            <Link to={item.routing}>
              {icon} <span>{item.title}</span>
            </Link>
          </Menu.Item>
        );
      }
    };

    return (
      <Sider
        collapsible={true}
        collapsed={this.state.collapsed}
        onCollapse={() => this.onCollapse(!this.state.collapsed)}
        className="left-navbar"
      >
        <Link to={"/"}>
          <div className="brand">
            <img
              alt="logo"
              src={logo}
              className="brand-item"
              style={{ marginTop: 5, marginLeft: 5, marginRight: 10 }}
            />
            {!this.state.collapsed && (
              <div className="brand-item" style={{ marginTop: 5 }}>
                NaTourWine
              </div>
            )}
          </div>
        </Link>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[]} defaultOpenKeys={["10", "100"]}>
          {this.state.treeMenu && this.state.treeMenu.map((item: ILeftMenuItem) => drawMenuItem(item))}
        </Menu>
      </Sider>
    );
  }

  // This method convert the input flat array in a tree structure
  // It's need to build Tree menu
  private unflatten<T = any>(arr: Array<T>): Array<T> {
    const tree: Array<T> = [];
    const mappedArr: { [index: string]: any } = {};
    let arrElem: any;
    let mappedElem: any;
    const keyChildren = "children";
    const keyParentid = "parentid";

    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id][keyChildren] = [];
    }

    for (const id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentid) {
          mappedArr[mappedElem[keyParentid]][keyChildren].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }
}
