import { AuthConstants } from '../costants/auth.constants';
import { IAuthActionsPayload } from '../interfaces/auth.interfaces';
import { ITokenData } from '../../authProvider';
import { Dispatch } from 'redux';
import { Entities} from '../../constants';
import { sendRequest, requestOK } from './common.actions';

const updateUserData = (userData: IAuthActionsPayload) => ({
  type: AuthConstants.Login,
  payload: userData,
});

export const authActions = {
  login: (loggedUserData: ITokenData) => async (dispatch: Dispatch) => {
    dispatch(sendRequest(Entities.Auth));
    dispatch(requestOK(Entities.Auth));
    dispatch(
      updateUserData({
        loggedUserData,
      })
    );
  },
  logout: () => ({
    type: AuthConstants.Logout,
  }),
};
