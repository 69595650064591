import { Asset } from "../../common/interfaces";
import { nameof } from "../../utils";
import { ColumnsDataType, ITableColumn } from "../../hoc/TableFactory";
import { Empty } from "antd";

export const cols = [
  {
    title: "Image",
    dataIndex: nameof<Asset>("base64Data"),
    key: nameof<Asset>("base64Data"),
    render: (value) => {
      return (
        <>
          {value && (
            <div style={{ textAlign: "center", maxWidth: 64 }}>
              <img src={value} style={{ width: 64 }} alt="no data" />
            </div>
          )}
          {!value && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={false}
              style={{ width: 64 }}
              imageStyle={{ maxHeight: 32, maxWidth: 64 }}
            />
          )}
        </>
      );
    },
  },
  {
    title: "Name",
    dataIndex: nameof<Asset>("name"),
    key: nameof<Asset>("name"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
] as ITableColumn<Asset>[];
