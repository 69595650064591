import { Entities } from "../../common/constants";
import {
  catchErrorClbk,
  entityDalFactory,
  entityUrlBuilder,
  GenericDalOperation,
  IDalCRUDSchema,
  ServerError,
} from "../../hoc/dalFactory";
import { User } from "../../common/interfaces";
import axios from "axios";

export const usersDal = entityDalFactory<User, IDalCRUDSchema<User>>({
  enableOperation: GenericDalOperation.CRUD,
  entityName: Entities.Users,
  enableSchema: true,
  enableExport: true,
});

export const resendAction = async (user: User): Promise<boolean | { error: ServerError }> => {
  try {
    const url = `${entityUrlBuilder(Entities.Users)}/resend-activation`;
    const result = await axios.post(url, user);
    if (result.status !== 204) console.error(JSON.stringify(result));
    return result.status === 204;
  } catch (error) {
    return { error: catchErrorClbk(error) };
  }
};
