import React, { Suspense } from 'react';
import { ApplicationRouter } from './core-components/ApplicationRouter';
import './styles/index.less';
import { Spin } from 'antd';

function App() {
  return (
    <>
      <Suspense fallback={<div className="main-loading"><Spin size="large"/></div>}>
        <ApplicationRouter />
      </Suspense>
    </>
  );
}

export default App;
