import { FC } from "react";
import { Map, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { DesignMapProps, IMapPosition } from "./DesignMap.interface";
import "leaflet/dist/leaflet.css";
import { useState } from "react";
import { useEffect } from "react";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [30, 46],
  iconAnchor: [17, 46],
});

L.Marker.prototype.options.icon = DefaultIcon;

export const DesignMap: FC<DesignMapProps> = (props) => {
  const [polyline, setPolyline] = useState<IMapPosition[]>([]);
  const {
    zoom,
    renderCenterMarker: renderMarker,
    mapsHeight,
    centerPosition,
    points,
    drawPolyline,
    mapsType,
    handleMapsClick,
    reduceHeightPx,
  } = props;
  const defRenderMarker = renderMarker !== undefined ? renderMarker : true;
  const centerMarker = centerPosition ? <Marker position={centerPosition} /> : "";

  const mapsUrl =
    mapsType === "street"
      ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      : "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

  const attribution =
    mapsType === "street"
      ? '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      : "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";

  useEffect(() => {
    if (points) {
      const arr = points.map((p) => p.pos);
      setPolyline(arr);
    }
  }, [points]);

  return (
    <Map
      center={centerPosition || [42.393368, 13.094724]}
      onClick={(e: any) => (handleMapsClick ? handleMapsClick(e) : "")}
      zoom={zoom ? zoom : 13}
      style={{ width: "100%", height: mapsHeight || window.innerHeight - (reduceHeightPx || 10) }}
    >
      <TileLayer attribution={attribution} url={mapsUrl} />
      {defRenderMarker && centerMarker}
      {points &&
        points.length > 0 &&
        points.map((p) => (
          <Marker position={p.pos} icon={p.icon || DefaultIcon}>
            {p.popupContent && <Popup>{p.popupContent}</Popup>}
          </Marker>
        ))}
      {points && drawPolyline && <Polyline color="red" positions={polyline} />}
    </Map>
  );
};
