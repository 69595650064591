import { WidgetProps } from "@rjsf/core";
import { TimePicker, InputNumber, Row, Col } from "antd";
import { Entities, ExtraServices, WineAssociations } from "../../common/constants";
import { multipleEnumWidgetFactory } from "./multipleEnumWidgetFactory";
import moment from "moment";
import { Lookup } from "../../common/components/Lookup";
import { FC, useEffect, useRef, useState } from "react";
import { entityDalFactory, GenericDalOperation, IDalCRUDSchema } from "../dalFactory";
import { entityTableCRUDFactory, ITableCrudProps } from "../TableFactory";
import { Asset, PointOfInterest, Version, Winery } from "../../common/interfaces";
import { wineryCols, poiCols, assetCols } from "./customWidget.constants";

export const AssociationWidget = multipleEnumWidgetFactory(WineAssociations);

export const ExtraServicesWidget = multipleEnumWidgetFactory(ExtraServices);

export const TimePickerWidget = (props: WidgetProps) => {
  return (
    <div>
      <TimePicker format="HH:mm:ss" value={props.value && moment(props.value, "HH:mm:ss")} onChange={(_value, timeStr) => props.onChange(timeStr)} />
    </div>
  );
};

export const LookupWidget = (props: WidgetProps) => {
  const [showLookup, setShowLookup] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>();
  const [initialData, setInitialData] = useState<any>();
  const dal = useRef<any>();
  const { options, id } = props;

  let LookupTable: FC<ITableCrudProps<any>> | null = null;

  if (options && options.entity) {
    switch (options.entity) {
      case "Winery":
        const wineriesDal = entityDalFactory<Winery, IDalCRUDSchema<Winery>>({
          enableOperation: GenericDalOperation.CRUD,
          entityName: Entities.Wineries,
          enableSchema: true,
          enableExport: false,
        });
        dal.current = wineriesDal;
        LookupTable = entityTableCRUDFactory({
          tableId: "wineries-lookup",
          dal: wineriesDal,
          cols: wineryCols,
          addEnabled: false,
          deleteEnabled: false,
          editEnabled: false,
          selectionType: "radio",
          useTableDataForEditing: false,
        });
        break;
      case "PointOfInterest":
        const poisDal = entityDalFactory<PointOfInterest, IDalCRUDSchema<PointOfInterest>>({
          enableOperation: GenericDalOperation.CRUD,
          entityName: Entities.POIs,
          enableSchema: true,
          enableExport: false,
        });
        dal.current = poisDal;
        LookupTable = entityTableCRUDFactory({
          tableId: "pois-lookup",
          dal: poisDal,
          cols: poiCols,
          addEnabled: false,
          deleteEnabled: false,
          editEnabled: false,
          selectionType: "radio",
          useTableDataForEditing: false,
        });
        break;
      case "Asset":
        const assetDal = entityDalFactory<Asset, IDalCRUDSchema<Asset>>({
          enableOperation: GenericDalOperation.CRUD,
          entityName: Entities.Assets,
          enableSchema: true,
          enableExport: false,
        });
        dal.current = assetDal;
        LookupTable = entityTableCRUDFactory({
          tableId: "asset-lookup",
          dal: assetDal,
          cols: assetCols,
          addEnabled: false,
          deleteEnabled: false,
          editEnabled: false,
          selectionType: "radio",
          useTableDataForEditing: false,
        });
        break;
    }
  }

  useEffect(() => {
    if (dal && dal.current && !selected && initialData === undefined && props.value) {
      dal.current
        .getById(props.value)
        .then((result: any) => {
          if (result.data) {
            const { data } = result;
            setInitialData(data);
          }
        })
        .catch((_: any) => {
          setInitialData(false);
        });
    }
  }, [dal, selected, initialData, props.value]);

  return (
    <Lookup
      value={selected && selected.name ? selected.name : initialData && initialData.name ? initialData.name : ""}
      placeholder="Select one element"
      title="Select element"
      lookupId={id}
      showLookup={showLookup}
      openLookup={(open) => setShowLookup(open)}
    >
      {LookupTable && (
        <LookupTable
          onSelectionCallback={(selected) => {
            if (selected) {
              props.onChange(selected[0]._id);
              setSelected(selected[0]);
            }
          }}
        />
      )}
    </Lookup>
  );
};

export const VersionWidget = (props: WidgetProps) => {
  const [version, setVersion] = useState<Version>();
  const { value } = props;

  useEffect(() => {
    if (value) setVersion(value);
  }, [value]);

  return (
    <Row>
      <Col>
        <InputNumber
          value={version?.major}
          onChange={(value) => {
            props.onChange({ ...version, major: value });
          }}
        />
      </Col>
      <Col>
        <InputNumber
          value={version?.minor}
          onChange={(value) => {
            props.onChange({ ...version, minor: value });
          }}
        />
      </Col>
      <Col>
        <InputNumber
          value={version?.build}
          onChange={(value) => {
            props.onChange({ ...version, build: value });
          }}
        />
      </Col>
      <Col>
        <InputNumber
          value={version?.revision}
          onChange={(value) => {
            props.onChange({ ...version, revision: value });
          }}
        />
      </Col>
    </Row>
  );
};
