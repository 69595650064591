import { FC } from "react";
import { IFlexPanelLeftProps } from "./FlexPanelLeft.interfaces";

export const FlexPanelLeft: FC<IFlexPanelLeftProps> = (props) => {
  const { size, children } = props;
  const className = size ? (size === "half" ? "flex-panel-left-half" : "flex-panel-left") : "flex-panel-left";
  return (
    <div className={`flex-panel ${className}`}>
      <div className="panel-container">{children}</div>
    </div>
  );
};
