import { FC } from "react";
import { usersDal } from "./Users.dal";
import { IJsonFormProps, jsonFormFactory } from "../../hoc/FormFactory";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { FlexContainer } from "../../common/components/FlexContainer";

const UserForm: FC<IJsonFormProps> = jsonFormFactory(usersDal, "users");

export const User: FC<{ match: any }> = (props) => {
  const title = props.match?.params?.id ? "Modify User data" : "Add new User";
  return (
    <FlexContainer>
      <FlexPanelMiddle title={title} panelClassName="md-card-3" backButton>
        <UserForm {...props} />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
