import { FC } from "react";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { Roles } from "../../common/constants";
import { useAdministrativePage } from "../../common/customHooks/useAdministrativePage";
import { useReadOnlyPage } from "../../common/customHooks/useReadOnlyPage";
import { Winery } from "../../common/interfaces";
import { entityTableCRUDFactory, ITableCrudProps } from "../../hoc/TableFactory";
import { cols } from "./Wineries.constants";
import { wineriesDal, wineriesDataDal } from "./Wineries.dal";

const WineryTable: FC<ITableCrudProps<Winery>> = entityTableCRUDFactory({
  tableId: "wineries",
  dal: wineriesDal,
  dalR: wineriesDataDal,
  cols,
  formUrl: "winery",
  addEnabled: true,
  editEnabled: true,
  deleteEnabled: true,
  useTableDataForEditing: false,
});

export const Wineries = () => {
  useAdministrativePage();
  const readOnly = useReadOnlyPage([Roles.Staff]);

  return (
    <FlexContainer>
      <FlexPanelMiddle title={"Wineries Registry"} panelClassName="md-card-3">
        <WineryTable canExport={!readOnly} canDelete={!readOnly} canEdit={!readOnly} />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
