import { User } from "../../common/interfaces";
import { nameof } from "../../utils";
import {
  booleanFilterColFactory,
  ColumnsDataType,
  ITableColumn,
  renderBooleanDataFunction,
  renderDateStringFunction,
} from "../../hoc/TableFactory";

export const cols = [
  {
    title: "User Name",
    dataIndex: nameof<User>("userName"),
    key: nameof<User>("userName"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Email",
    dataIndex: nameof<User>("email"),
    key: nameof<User>("email"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: nameof<User>("firstName"),
    key: nameof<User>("firstName"),
    dataType: ColumnsDataType.string,
    sorter: true,
    responsive: ["xl"],
  },
  {
    title: "Surname",
    dataIndex: nameof<User>("lastName"),
    key: nameof<User>("lastName"),
    dataType: ColumnsDataType.string,
    sorter: true,
    responsive: ["xl"],
  },
  {
    title: "Registered On",
    dataIndex: nameof<User>("registeredOn"),
    key: nameof<User>("registeredOn"),
    dataType: ColumnsDataType.date,
    render: renderDateStringFunction,
    sorter: true,
    responsive: ["xl"],
  },
  {
    title: "Last login",
    dataIndex: nameof<User>("lastLogin"),
    key: nameof<User>("lastLogin"),
    dataType: ColumnsDataType.date,
    render: renderDateStringFunction,
    sorter: true,
    responsive: ["xl"],
  },
  {
    title: "Role",
    dataIndex: nameof<User>("role"),
    key: nameof<User>("role"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Active",
    dataIndex: nameof<User>("isActive"),
    key: nameof<User>("isActive"),
    dataType: ColumnsDataType.boolean,
    render: renderBooleanDataFunction,
    filters: booleanFilterColFactory(),
  },
  {
    title: "FB",
    dataIndex: nameof<User>("fromFacebook"),
    key: nameof<User>("fromFacebook"),
    dataType: ColumnsDataType.boolean,
    render: renderBooleanDataFunction,
    filters: booleanFilterColFactory(),
  },
  {
    title: "Apple",
    dataIndex: nameof<User>("fromApple"),
    key: nameof<User>("fromApple"),
    dataType: ColumnsDataType.boolean,
    render: renderBooleanDataFunction,
    filters: booleanFilterColFactory(),
  },
] as ITableColumn<User>[];
