import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Home } from "../../screens/Home/Home";
import { Journeys } from "../../screens/Journeys";
import { Journey } from "../../screens/Journeys/JourneysForm";
import { JourneyStage } from "../../screens/Journeys/JourneyStageForm";
import { Poi, POIs } from "../../screens/POIs";
import { User, Users } from "../../screens/Users";
import { Wineries, WineryForm } from "../../screens/Wineries";
import { WineriesMap } from "../../screens/WineriesMap";
import { Assets, AssetForm } from "../../screens/AssetsRegistry";
import { JourneysMap } from "../../screens/JourneysMap";
import { FormSchemas, JsonSchemasForm } from "../../screens/FormSchemasEntries";

export const PrivateRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/map/wineries" component={WineriesMap} />
      <Route exact path="/map/journeys" component={JourneysMap} />
      <Route exact path="/user/:id?" component={User} />
      <Route exact path="/wineries" component={Wineries} />
      <Route exact path="/winery/:id?" component={WineryForm} />
      <Route exact path="/form-schemas" component={FormSchemas} />
      <Route exact path="/form-schema/:id?" component={JsonSchemasForm} />
      <Route exact path="/assets" component={Assets} />
      <Route exact path="/asset/:id?" component={AssetForm} />
      <Route exact path="/pois" component={POIs} />
      <Route exact path="/poi/:id?" component={Poi} />
      <Route exact path="/journeys" component={Journeys} />
      <Route exact path="/journey/:id?" component={Journey} />
      <Route exact path="/journey/:id/stage/:idStage?" component={JourneyStage} />
      <Redirect to="/404" />
    </Switch>
  );
};
