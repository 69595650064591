import React, { FC, Suspense } from "react";
import { BrowserRouter, BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { authProvider } from "../../common/authProvider";
import { Login } from "../../screens/Login";
import { Whoops404 } from "../../screens/404";
import { Spin } from "antd";
import { useMountEffect } from "../../common/customHooks/useMountEffect";
import { UiShell } from "../UiShell";

const { useAuth, logout } = authProvider;

const RenderLazyComponent: FC<{ component: any }> = (props) => {
  const { component: LazyComponent } = props;
  return (
    <Suspense
      fallback={
        <div className="main-loading">
          <Spin size="large" />
        </div>
      }
    >
      <LazyComponent />
    </Suspense>
  );
};

export const ApplicationRouter: React.FC = () => {
  const [isLogged] = useAuth();

  useMountEffect(() => {
    if (process.env.REACT_APP_ENABLE_DEVELOPMENT === "false") {
      logout();
    }
  });

  return (
    <BrowserRouter>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/404" component={Whoops404} />
          {isLogged && <Route path="/" render={() => <RenderLazyComponent component={UiShell} />} />}
          {!isLogged && <Redirect to="/login" />}
        </Switch>
      </Router>
    </BrowserRouter>
  );
};
