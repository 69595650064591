import { useEffect, useState } from "react";
import { Roles } from "../constants";
import { TokenProvider } from "./tokenProvider";
import { ILoginUserToken, ITokenData } from "./tokenProvider.interfaces";

const createAuthProvider = () => {
  const tp = TokenProvider.getInstance();

  /** Execute login, save user token on local cache and set Axios interceptor for refreshing expired tokens */
  const login = (newTokens: ILoginUserToken) => {
    tp.setToken(newTokens);
  };

  /** Execute logout, delete saved auth token from local cache */
  const logout = () => {
    tp.setToken(null);
  };

  /** React custom Hook that grant access to login information inside funcion component */
  const useAuth = () => {
    const [isLogged, setIsLogged] = useState<boolean>(tp.isLoggedIn());
    const [tokenData, setTokenData] = useState<ITokenData | undefined>(tp.getUserData());

    useEffect(() => {
      const listener = (newIsLogged: boolean, tokenData: ITokenData | undefined) => {
        setIsLogged(newIsLogged);
        setTokenData(tokenData);
      };
      tp.subscribe(listener);
      return () => {
        tp.unsubscribe(listener);
      };
    }, []);

    return [isLogged, tokenData] as [typeof isLogged, typeof tokenData];
  };

  /** Access to decoded user data for class and functions outside React Component */
  const loggedUserData = () => tp.getUserData();

  /** Access to JWT Token */
  const jwtToken = async () => await tp.getToken();

  /** Utility function to check if user is administrative  */
  const isAdministrativeUser = (userRole: string) =>
    userRole === Roles.Admin || userRole === Roles.SuperAdmin || userRole === Roles.Staff;

  return { useAuth, login, logout, loggedUserData, jwtToken, isAdministrativeUser };
};

export const authProvider = createAuthProvider();
