import { Empty } from "antd";
import { EntityStatus, PointOfInterestType } from "../../common/constants";
import { Asset, PointOfInterest, Winery } from "../../common/interfaces";
import { nameof } from "../../utils";
import { ColumnsDataType, enumeratorFilterColFactory, ITableColumn, renderEnumDataColsFactory } from "../TableFactory";

export const wineryCols = [
  {
    title: "Name",
    dataIndex: nameof<Winery>("name"),
    key: nameof<Winery>("name"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Address",
    dataIndex: nameof<Winery>("address"),
    key: nameof<Winery>("address"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Region",
    dataIndex: nameof<Winery>("region"),
    key: nameof<Winery>("region"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: nameof<Winery>("status"),
    key: nameof<Winery>("status"),
    dataType: ColumnsDataType.enumerator,
    render: renderEnumDataColsFactory(EntityStatus),
    filters: enumeratorFilterColFactory(EntityStatus),
  },
] as ITableColumn<Winery>[];

export const poiCols = [
  {
    title: "Name",
    dataIndex: nameof<PointOfInterest>("name"),
    key: nameof<PointOfInterest>("name"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Country",
    dataIndex: nameof<PointOfInterest>("country"),
    key: nameof<PointOfInterest>("country"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Region",
    dataIndex: nameof<PointOfInterest>("region"),
    key: nameof<PointOfInterest>("region"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: nameof<PointOfInterest>("type"),
    key: nameof<PointOfInterest>("type"),
    dataType: ColumnsDataType.enumerator,
    render: renderEnumDataColsFactory(PointOfInterestType),
    filters: enumeratorFilterColFactory(PointOfInterestType),
  },
] as ITableColumn<PointOfInterest>[];

export const assetCols = [
  {
    title: "Image",
    dataIndex: nameof<Asset>("base64Data"),
    key: nameof<Asset>("base64Data"),
    render: (value) => {
      return (
        <>
          {value && (
            <div style={{ textAlign: "center", maxWidth: 64 }}>
              <img src={value} style={{ width: 64 }} alt="no data" />
            </div>
          )}
          {!value && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={false}
              style={{ width: 64 }}
              imageStyle={{ maxHeight: 32, maxWidth: 64 }}
            />
          )}
        </>
      );
    },
  },
  {
    title: "Name",
    dataIndex: nameof<Asset>("name"),
    key: nameof<Asset>("name"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
] as ITableColumn<Asset>[];
