import { PlusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useHistory } from "react-router";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { Roles } from "../../common/constants";
import { useAdministrativePage } from "../../common/customHooks/useAdministrativePage";
import { useReadOnlyPage } from "../../common/customHooks/useReadOnlyPage";
import { entityTableCRUDFactory } from "../../hoc/TableFactory";
import { cols, subCols } from "./Journeys.constants";
import { journeysDal, journeyStagesDalBuilder } from "./Journeys.dal";

const JourneysTable = entityTableCRUDFactory({
  tableId: "journeys",
  dal: journeysDal,
  cols,
  formUrl: "journey",
  addEnabled: true,
  editEnabled: true,
  deleteEnabled: true,
  useTableDataForEditing: false,
});

export const Journeys = () => {
  useAdministrativePage();
  const history = useHistory();
  const readOnly = useReadOnlyPage([Roles.Staff]);

  return (
    <FlexContainer>
      <FlexPanelMiddle title={"Journeys Registry"} panelClassName="md-card-3">
        <JourneysTable
          expandable={{
            expandedRowRender: ({ _id }) => {
              const dal = journeyStagesDalBuilder(_id);
              const StageTable = entityTableCRUDFactory({
                tableId: "journey-stages",
                dal,
                cols: subCols,
                formUrl: `journey/${_id}/stage`,
                editEnabled: true,
                deleteEnabled: true,
                useTableDataForEditing: false,
              });
              return <StageTable canExport={!readOnly} canDelete={!readOnly} canEdit={!readOnly} />;
            },
            rowExpandable: ({ hasStages }) => hasStages || false,
          }}
          customActions={[
            (record) => (
              <Tooltip title="Add one new stage">
                <PlusOutlined onClick={() => history.push(`/journey/${record._id}/stage`)} />
              </Tooltip>
            ),
          ]}
          canExport={!readOnly}
          canDelete={!readOnly}
          canEdit={!readOnly}
        />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
