import { useEffect } from "react";
import { useHistory } from "react-router";
import { authProvider } from "../authProvider";
import { openNotificationWithIcon } from "../components/Notification";

const { useAuth, isAdministrativeUser } = authProvider;

export const useAdministrativePage = () => {
  const [isLogged, userData] = useAuth();
  const history = useHistory();

  useEffect(() => {
    const accessDenied = () => {
      openNotificationWithIcon("warning", "Access Denied", "Your user can't enter this page.");
      history.push("/");
      return;
    };

    if (isLogged === false) {
      accessDenied();
    }

    if (userData) {
      if (!isAdministrativeUser(userData.role)) {
        accessDenied();
      }
    }
  }, [userData, isLogged, history]);
};
