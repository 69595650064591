import storage from 'redux-persist/lib/storage';
import { PersistConfig } from 'redux-persist';
import { nameof } from '../../utils/nameof';
import { IReduxStore } from './reduxStore.interfaces';

/**
 * List of reducers that will be persisted and rehydrated into redux
 */
const reducersWhiteList: string[] = [
  /*  IMPORTANT: PUT HERE ALL REDUCERS THAT YOU WANT TO PERSIST ON LOCALSTORAGE
      AND REMEMBER TO PUT CONDITIONAL REMOTE LOADING TO "src\redux\actions\reduxStorePreloader.actions.ts"
      REMOTE LOADING MUST BE LAUNCHED ONLY IF DATA IS EMPTY AFTER REHIDRATATION */
  nameof<IReduxStore>('auth'),
];

/**
 * Persist base type config file
 */
const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  whitelist: reducersWhiteList, // only navigation will be persisted
  version: 1, // used to invalidate store
};

/**
 * It provide the persist redux store configuration
 */
export const persistStoreConfiguration = {
  /**
   * Persist store configuration in redux-persist version
   */
  persistConfig,
  /**
   * Persist white list used inside persist configuration
   */
  reduxPersistWhiteList: reducersWhiteList,

  /**
   * It check if your reducer is persisted or not
   * @param  {nameof<IReduxStore>} reducer that you whant to check
   * @returns boolean [true]: persisted, [false]: not persisted
   */
  isPersisted: (reducer: keyof IReduxStore): boolean => {
    return reducersWhiteList.findIndex((el) => el === reducer) > -1;
  },
};
