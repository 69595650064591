import { Steps, List, Spin, Button } from "antd";
import { useEffect, useState } from "react";
import { DesignMap } from "../../common/components/DesignMap/DesignMap";
import { IMapPoint } from "../../common/components/DesignMap/DesignMap.interface";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { Asset, Journey, JourneyStage, PointOfInterest, Winery } from "../../common/interfaces";
import { journeysDal, journeyStagesDalBuilder, markerDal } from "./JourneysMap.dal";
import { openNotificationWithIcon } from "../../common/components/Notification";
import { useMountEffect } from "../../common/customHooks/useMountEffect";
import { PoisPopup, WineryPopup } from "../../common/components/DesignMap/MarkerPopup";
import { getMarkerIcon } from "../../common/components/DesignMap/MarkerIcon";
import { PointOfInterestType } from "../../common/constants";
import { Panel, PanelBody, PanelHead } from "../../common/components/Panel";
import { FlexPanelLeft } from "../../common/components/FlexPanelLeft";
import { FlexPanelRight } from "../../common/components/FlexPanelRight";
import { notEmpty } from "../../utils";

const { Step } = Steps;

export const JourneysMap = () => {
  const [current, setCurrent] = useState<number>(0);
  const [loadedPage, setLoadedPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStages, setLoadingStages] = useState<boolean>(false);
  const [loadingPoints, setLoadingPoints] = useState<boolean>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>();
  const [journeys, setJourneys] = useState<Journey[]>([]);
  const [markers, setMarker] = useState<Asset[]>();
  const [selectedJourney, setSelectedJourney] = useState<Journey>();
  const [selectedStages, setSelectedStages] = useState<JourneyStage[]>();
  const [selectedJourneyDays, setSelectedJourneyDays] = useState<number[]>([]);
  const [selectedDay, setSelectedDay] = useState<number>();
  const [points, setPoints] = useState<IMapPoint[]>([]);

  const loadMoreData = (page: number) => {
    if (totalPages && page === totalPages) {
      openNotificationWithIcon("info", "Caricamento terminato", "Tutti gli elementi scaricati");
      setHasMore(false);
      return;
    }

    if (!loading) {
      setLoading(true);
      journeysDal.get({ pageNumber: page, pageSize: 25 }).then((result) => {
        if (result && result.data && result.data.data) {
          setJourneys([...journeys, ...result.data.data]);
          if (!totalPages) setTotalPages(result.data.totalPages);
          setLoadedPage(page);
        }
        setLoading(false);
      });
    }
  };

  useMountEffect(() => {
    loadMoreData(1);
    markerDal.get().then((result) => {
      if (result && result.data) {
        setMarker(result.data.data || []);
      }
    });
  });

  const loadMore = !loading && (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button onClick={() => loadMoreData(loadedPage + 1)}>load more</Button>
    </div>
  );

  useEffect(() => {
    if (current === 0 && selectedJourney) {
      setSelectedJourney(undefined);
      setSelectedDay(undefined);
      setPoints([]);
      setSelectedJourneyDays([]);
      setSelectedStages(undefined);
    } else if (selectedJourney && current === 1 && !selectedStages) {
      setLoadingStages(true);
      const stageDal = journeyStagesDalBuilder(selectedJourney._id);
      stageDal.get({}).then((result) => {
        if (result && result.data) {
          setSelectedStages(result.data.data || []);
          const preFilterDays = result.data.data?.map((s) => s.day || 0);
          if (preFilterDays) {
            const days = preFilterDays.filter((item, index) => preFilterDays.indexOf(item) === index);
            setSelectedJourneyDays(days);
            setSelectedDay(1);
          }
          setLoadingStages(false);
        }
      });
    }
  }, [selectedJourney, current, selectedStages]);

  useEffect(() => {
    const buildMapMarkers = (wineries: { data: Winery; order?: number }[]): IMapPoint[] => {
      return wineries.map((w) => {
        const { data, order } = w;
        return {
          pos: { lat: data.location?.latitude || 0, lng: data.location?.longitude || 0 },
          popupContent: WineryPopup(data),
          icon: markers && getMarkerIcon(data.region || "", markers),
          order: order,
        };
      });
    };

    const buildPoisMarkers = (pois: { data: PointOfInterest; order?: number }[]): IMapPoint[] => {
      return pois.map((p) => {
        const { data, order } = p;
        return {
          pos: { lat: data.location?.latitude || 0, lng: data.location?.longitude || 0 },
          popupContent: PoisPopup(data),
          icon:
            markers &&
            getMarkerIcon(data.type ? PointOfInterestType[data.type] : "", markers, data.type ? false : true),
          order: order,
        };
      });
    };

    if (!loadingStages && selectedJourney && selectedStages && selectedDay) {
      setLoadingPoints(true);
      const wineries = selectedStages
        .map((stage) => {
          const { wineryData: wineyData, stageDayOrdering, day } = stage;
          if (wineyData && day === selectedDay) return { data: wineyData, order: stageDayOrdering };
          return undefined;
        })
        .filter(notEmpty);

      const pois = selectedStages
        .map((stage) => {
          const { pointOfInterestData, stageDayOrdering, day } = stage;
          if (pointOfInterestData && day === selectedDay) return { data: pointOfInterestData, order: stageDayOrdering };
          return undefined;
        })
        .filter(notEmpty);

      const wineryPoint = buildMapMarkers(wineries);
      const poisPoint = buildPoisMarkers(pois);
      const allPoints = [...wineryPoint, ...poisPoint];
      setPoints(
        allPoints.sort((a, b) => {
          if (!a.order || !b.order) return 0;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        })
      );
      setLoadingPoints(false);
    }
  }, [loadingStages, selectedJourney, selectedStages, markers, loadingPoints, selectedDay]);

  return (
    <FlexContainer>
      <div style={{ clear: "both", width: "100%", padding: 20 }}>
        <Steps current={current}>
          <Step key="journey" title="Select Journey" onClick={() => setCurrent(0)} />
          <Step key="stages" title="View Stages" />
        </Steps>
      </div>
      {current === 0 && (
        <FlexPanelMiddle panelClassName="md-card-3">
          <List
            dataSource={journeys}
            loadMore={loadMore}
            loading={loading}
            renderItem={(item) => (
              <List.Item key={item._id}>
                <List.Item.Meta title={item.title} description={item.description || "--- Journey description ---"} />
                <Button
                  onClick={() => {
                    setSelectedJourney(item);
                    setCurrent(current + 1);
                  }}
                  type="link"
                >
                  Select
                </Button>
              </List.Item>
            )}
          >
            {loading && hasMore && (
              <div>
                <Spin />
              </div>
            )}
          </List>
        </FlexPanelMiddle>
      )}
      {journeys && current === 1 && selectedJourney && selectedStages && loadingPoints === false && (
        <>
          <FlexPanelLeft>
            <Panel>
              <PanelHead title="Giorno di viaggio" />
              <PanelBody>
                <List
                  dataSource={selectedJourneyDays}
                  renderItem={(item) => (
                    <List.Item>
                      {item}
                      <Button
                        onClick={() => {
                          setSelectedDay(item);
                        }}
                        type="link"
                      >
                        {"Visualizza >"}
                      </Button>
                    </List.Item>
                  )}
                />
              </PanelBody>
            </Panel>
          </FlexPanelLeft>
          <FlexPanelRight>
            <Panel>
              <PanelHead title={`Tappe giorno ${selectedDay}`} />
              <PanelBody>
                <DesignMap
                  mapsHeight={450}
                  renderCenterMarker={false}
                  centerPosition={points && points.length > 0 ? points[0].pos : undefined}
                  points={points}
                  zoom={10}
                  drawPolyline
                />
              </PanelBody>
            </Panel>
          </FlexPanelRight>
        </>
      )}
    </FlexContainer>
  );
};
