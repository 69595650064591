import { PointOfInterest } from "../../common/interfaces";
import { nameof } from "../../utils";
import {
  ColumnsDataType,
  enumeratorFilterColFactory,
  ITableColumn,
  renderEnumDataColsFactory,
} from "../../hoc/TableFactory";
import { PointOfInterestType } from "../../common/constants";

export const cols = [
  {
    title: "Name",
    dataIndex: nameof<PointOfInterest>("name"),
    key: nameof<PointOfInterest>("name"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Country",
    dataIndex: nameof<PointOfInterest>("country"),
    key: nameof<PointOfInterest>("country"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Region",
    dataIndex: nameof<PointOfInterest>("region"),
    key: nameof<PointOfInterest>("region"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Type",
    dataIndex: nameof<PointOfInterest>("type"),
    key: nameof<PointOfInterest>("type"),
    dataType: ColumnsDataType.enumerator,
    render: renderEnumDataColsFactory(PointOfInterestType),
    filters: enumeratorFilterColFactory(PointOfInterestType),
  },
] as ITableColumn<PointOfInterest>[];
