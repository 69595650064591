export enum notificationsConstants {
  PUSH_NOTIFICATION_REQ = 'PUSH_NOTIFICATION_REQ',
  PUSH_NOTIFICATION_OK = 'PUSH_NOTIFICATION_OK',
  PUSH_NOTIFICATION_KO = 'PUSH_NOTIFICATION_KO',
  REFRESH_NOTIFICATION = 'REFRESH_NOTIFICATION',
  SET_READ_ALL_REQ = 'SET_READ_ALL_REQ',
  SET_READ_ALL_OK = 'SET_READ_ALL_OK',
  SET_READ_ALL_KO = 'SET_READ_ALL_KO',
  EDIT_NOTIFICATION_REQ = 'EDIT_NOTIFICATION_REQ',
  EDIT_NOTIFICATION_OK = 'EDIT_NOTIFICATION_OK',
  EDIT_NOTIFICATION_KO = 'EDIT_NOTIFICATION_KO',
}

export enum statusISignalRMessage {
  Start = 1,
  FunctionMessageSent = 2,
  FunctionMessageError = 3,
  FunctionError = 4,
  InProgress = 5,
  DispatcherError = 6,
  End = 7
}

export enum NotificationStates {
  processing = 1,
  complete = 2,
  error = 3,
}

export const notificationInitialState = {
  notifications: [], // notifications list
  notRead: 0,
  pageSize: 10,
  totalCount: 0,
  currentPage: 0,
  isLoading: false,
  isError: false,
  errorMsg: null,
}