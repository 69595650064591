import { FC } from "react";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { IJsonFormProps, jsonFormFactory } from "../../hoc/FormFactory";
import { journeysDal } from "./Journeys.dal";

const JourneyStageForm: FC<IJsonFormProps> = jsonFormFactory(journeysDal, "journeys");

export const JourneyStage: FC<{ match: any }> = (props) => {
  const title = props.match?.params?.id ? "Modify Stage data" : "Add new Stage";
  return (
    <FlexContainer>
      <FlexPanelMiddle title={title} panelClassName="md-card-3" backButton>
        <JourneyStageForm {...props} schemaUrl="journeystages/schema" />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
