import { FormSchema, Version } from "../../common/interfaces";
import { nameof } from "../../utils";
import { ColumnsDataType, ITableColumn } from "../../hoc/TableFactory";

export const cols = [
  {
    title: "Name",
    dataIndex: nameof<FormSchema>("schemaName"),
    key: nameof<FormSchema>("schemaName"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Version",
    dataIndex: nameof<FormSchema>("schemaVersion"),
    key: nameof<FormSchema>("schemaVersion"),
    render: (value: Version) =>
      value ? (
        <span>
          {value.major}.{value.minor}
          {value.build > -1 ? `.${value.build}` : ""}
          {value.revision > -1 ? `.${value.revision}` : ""}
        </span>
      ) : (
        <span>---</span>
      ),
  },
] as ITableColumn<FormSchema>[];
