import { applyMiddleware, combineReducers, compose, createStore, Store, AnyAction } from 'redux';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { authReducer } from './reducers/auth.reducer';
import { persistStoreConfiguration } from './persistStoreConfiguration';
import { authProvider } from '../authProvider';
import { IReduxStore } from './reduxStore.interfaces';
import { initialLoadingStatus } from './reduxStore.constants';
import { IAuthState } from './interfaces/auth.interfaces';
// import { commonReducer } from './reducers/common.reducer';

/**
 * This global interface was added to handle the REDUX DEV TOOLS functions.
 */
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

declare let window: any;

export const appReducers = combineReducers<IReduxStore>({
  auth: authReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';
const persistedReducer = persistReducer(persistStoreConfiguration.persistConfig, appReducers);

const getInitialState = async (): Promise<Partial<IReduxStore>> => {
  const auth: IAuthState = {
    loggedUserData: authProvider.loggedUserData(),
    requestStatus: { ...initialLoadingStatus },
  };
  return {
    auth,
  };
};

const createApplicationStore = async (): Promise<[Store<IReduxStore>, Persistor]> => {
  try {
    const initialState = await getInitialState();
    const store = createStore(
      persistedReducer,
      initialState,
      enableDevTools
        ? composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<any, any>))
        : applyMiddleware(thunk as ThunkMiddleware<any, any>)
    ) as Store<any, AnyAction>;
    const persistor = persistStore(store);
    if (enableDevTools) console.log('ReduxDevTool is enable');
    return [store, persistor];
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

/**
 * This class is a singleton class that create and initialize redux store
 * with initial state just only once and share the same instance of that
 */
export class ReduxStoreSingleton {
  private static _instance?: ReduxStoreSingleton;
  private _store?: [Store<IReduxStore>, Persistor];

  public get store(): Store<IReduxStore> | undefined {
    return this._store ? this._store[0] : undefined;
  }

  public get persistor(): Persistor | undefined {
    return this._store ? this._store[1] : undefined;
  }

  private constructor() {}

  public static async getInstance(): Promise<ReduxStoreSingleton> {
    if (!this._instance) this._instance = new ReduxStoreSingleton();
    if (!this._instance._store) this._instance._store = await createApplicationStore();
    return this._instance;
  }
}
