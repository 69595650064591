import * as React from 'react';
import { Avatar, Layout, Popover, Tooltip, Button, Timeline, Drawer } from 'antd';
import { UserOutlined, BellOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { IRightNavBarProps, IRightNavBarState } from './rightNavBar.interfaces';
import { INotification } from '../../common/interfaces';
import { authActions, IReduxStore } from '../../common/redux';
import { authProvider } from '../../common/authProvider';

const { Sider } = Layout;

export class RightNavBarChild extends React.Component<IRightNavBarProps, IRightNavBarState> {
  constructor(props: IRightNavBarProps) {
    super(props);
    this.state = { 
      drawerVisible: false, 
    }
  }

  getUserPopupTitle() {
    return <span>{this.props.username || this.props.username !== null ? this.props.username : ""}</span>;
  }

  getUserPopupContent = () => (
    <div className="ant-popover-ts-container">
      <ul>
        <li>Ruolo: {"---"} </li>
        <li>Email: {(this.props.username) ? this.props.username : "---"}</li>
      </ul>
      <div className="container-buttons">
        <div className="btn-left" />
        <div className="btn-right">
          <Button size="small" type="primary" icon={<EditOutlined />}>Modifica</Button>
        </div>
      </div>
    </div>
  )
  
  showDrawer = (show: boolean = true) => {
    this.setState({ drawerVisible: show });
  };

  onChange = (e: any) => {
    this.setState({ placement: e.target.value });
  };

  onLogout = () => {
    const { dispatch } = this.props;
    if(dispatch) dispatch(authActions.logout());
    authProvider.logout();
  }

  render() {
    return (
      <div>
        <Sider
          breakpoint="xxl"
          trigger={null}
          collapsible={true}
          collapsed={true}
          collapsedWidth={60}
          className="right-navbar"
        >
          <Popover placement="leftTop" title={() => this.getUserPopupTitle()} content={() => this.getUserPopupContent()}>
            <UserOutlined className="item" style={{ height: '44px', width: '44px', borderRadius: '50px', border: '2px solid #fff', fontSize: '34px', color: '#6beb34' }} />
          </Popover>
          <Avatar className="item" size={44} icon={<BellOutlined />} style={{ backgroundColor: '#5a6872' }} />
          {/* <div onClick={this.showDrawer}>
            <Badge count={this.props.notifications}>
              <Avatar className="item" size={44} icon="bell" style={{ backgroundColor: '#5a6872' }} />
            </Badge>
          </div> */}
          <Tooltip title="Logout" placement="left">
            <div onClick={() => this.onLogout()}>
              <Avatar className="item" size={44} icon={<LogoutOutlined />} style={{ backgroundColor: '#d82829' }} />
            </div>
          </Tooltip>
        </Sider>
        <Drawer
          title={
            <div>
              <span className="ant-drawer-title-title">Notifiche</span>
              <span className="ant-drawer-title-description">Elenco delle ultime attività eseguite</span>
            </div>
          }
          placement={'right'}
          closable={true}
          onClose={() => this.showDrawer(false)}
          visible={this.state.drawerVisible}
          width={400}
          destroyOnClose={true}
        >
          <Timeline>
            {
              (this.props.notifications.length > 0) ?
              (this.props.notifications.slice(0, 10).map((notify: INotification) => {
              switch (notify.state) {
                default: {
                  return (
                    <Timeline.Item key={`notify_${notify.id}`} color="green">
                      {notify.title}
                      <br />
                      {`${new Date(notify.datetime).toLocaleDateString()} - ${new Date(
                        notify.datetime
                      ).toLocaleTimeString()}`}
                    </Timeline.Item>
                  );
                }
              }})) : (
                <Timeline.Item key={0} color="green">
                  Benvenuto!
                </Timeline.Item>
              )
            })
          </Timeline>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxStore, ownProps: IRightNavBarProps): IRightNavBarProps => {
  const { loggedUserData } = state.auth;
  return {
    username: loggedUserData?.unique_name,
    ...ownProps
  };
};

export const RightNavBar = connect(mapStateToProps)(RightNavBarChild);
