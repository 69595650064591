import { RightSquareOutlined } from "@ant-design/icons";
import { Drawer, Input } from "antd";
import { ILookupProps } from "./Lookup.interfaces";

export const Lookup = (props: ILookupProps) => {
  const toggleDrawer = (open: boolean) => {
    const { openLookup } = props;
    openLookup && openLookup(open);
  };

  const { value, placeholder, showLookup, title, disabled, children } = props;

  return (
    <>
      <Input
        suffix={<RightSquareOutlined onClick={() => (!disabled ? toggleDrawer(true) : undefined)} />}
        placeholder={placeholder || "Seleziona un elemento..."}
        value={`${value}`}
        title={`${value}`}
        readOnly={disabled}
      />
      <Drawer
        closable
        closeIcon
        title={title}
        onClose={() => toggleDrawer(false)}
        visible={showLookup || false}
        width={720}
      >
        {children}
      </Drawer>
    </>
  );
};
