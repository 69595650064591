import { Empty } from "antd";
import { FC, useState } from "react";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelLeft } from "../../common/components/FlexPanelLeft";
import { FlexPanelRight } from "../../common/components/FlexPanelRight";
import { Panel, PanelBody, PanelHead } from "../../common/components/Panel";
import { Asset } from "../../common/interfaces";
import { IJsonFormProps, jsonFormFactory } from "../../hoc/FormFactory";
import { markerDal } from "./Asset.dal";

const AssetJsonFormComponent: FC<IJsonFormProps> = jsonFormFactory(markerDal, "assets");

export const AssetForm: FC<{ match: any }> = (props) => {
  const [formData, setFormData] = useState<Asset>();

  return (
    <FlexContainer>
      <FlexPanelLeft size="half">
        <Panel>
          <PanelHead title="Image" />
          <PanelBody>
            <div style={{ textAlign: "center" }}>
              {formData && formData.base64Data ? (
                <img style={{ maxHeight: 256 }} src={`${formData.base64Data}`} alt="no data" />
              ) : (
                <Empty />
              )}
            </div>
          </PanelBody>
        </Panel>
      </FlexPanelLeft>
      <FlexPanelRight size="half">
        <Panel>
          <PanelHead title="Asset data" />
          <PanelBody>
            <AssetJsonFormComponent {...props} onChangeFormData={(data: Asset) => setFormData(data)} />
          </PanelBody>
        </Panel>
      </FlexPanelRight>
    </FlexContainer>
  );
};
