import { Journey, JourneyStage } from "../../common/interfaces";
import { nameof } from "../../utils";
import { ColumnsDataType, ITableColumn } from "../../hoc/TableFactory";

export const cols = [
  {
    title: "Title",
    dataIndex: nameof<Journey>("title"),
    key: nameof<Journey>("title"),
    dataType: ColumnsDataType.string,
    sorter: true,
  },
  {
    title: "Descriptions",
    dataIndex: nameof<Journey>("description"),
    key: nameof<Journey>("description"),
    dataType: ColumnsDataType.string,
  },
] as ITableColumn<Journey>[];

export const subCols = [
  {
    title: "Day",
    dataIndex: nameof<JourneyStage>("day"),
    key: nameof<JourneyStage>("day"),
    dataType: ColumnsDataType.string,
  },
  {
    title: "N°",
    dataIndex: nameof<JourneyStage>("stageDayOrdering"),
    key: nameof<JourneyStage>("stageDayOrdering"),
    dataType: ColumnsDataType.string,
  },
  {
    title: "Description",
    dataIndex: nameof<JourneyStage>("description"),
    key: nameof<JourneyStage>("description"),
    dataType: ColumnsDataType.string,
  },
  {
    title: "Name",
    dataIndex: nameof<JourneyStage>("name"),
    key: nameof<JourneyStage>("name"),
    dataType: ColumnsDataType.string,
  },
  {
    title: "Travel Time",
    dataIndex: nameof<JourneyStage>("travelTime"),
    key: nameof<JourneyStage>("travelTime"),
    dataType: ColumnsDataType.string,
  },
  {
    title: "Distance",
    dataIndex: nameof<JourneyStage>("distance"),
    key: nameof<JourneyStage>("distance"),
    dataType: ColumnsDataType.string,
  },
] as ITableColumn<JourneyStage>[];
