import { FC, useState, useCallback } from "react";
import { cols } from "./Users.constants";
import { resendAction, usersDal } from "./Users.dal";
import { entityTableCRUDFactory, ITableCrudProps } from "../../hoc/TableFactory";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { User } from "../../common/interfaces";
import { Roles } from "../../common/constants";
import { useAdministrativePage } from "../../common/customHooks/useAdministrativePage";
import { useReadOnlyPage } from "../../common/customHooks/useReadOnlyPage";
import { Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { Loading } from "../../common/components/Loading";
import { openNotificationWithIcon } from "../../common/components/Notification";

const UserTable: FC<ITableCrudProps<User>> = entityTableCRUDFactory({
  tableId: "users",
  dal: usersDal,
  cols,
  formUrl: "user",
  addEnabled: true,
  editEnabled: true,
  deleteEnabled: true,
  useTableDataForEditing: true,
});

export const Users = () => {
  useAdministrativePage();
  const readOnly = useReadOnlyPage([Roles.Staff, Roles.Admin]);
  const [loading, setLoading] = useState<boolean>(false);

  const resendCallback = useCallback((user: User) => {
    setLoading(true);
    resendAction(user)
      .then((r) => {
        setLoading(false);
        if (r) openNotificationWithIcon("success", "Success", "Sent again activation email");
        else openNotificationWithIcon("warning", "Warning", "Some error occour while executing operations");
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        setLoading(false);
      });
  }, []);

  return (
    <FlexContainer>
      <FlexPanelMiddle title={"Users Registry"} panelClassName="md-card-3">
        <UserTable
          canExport={!readOnly}
          canDelete={!readOnly}
          canEdit={!readOnly}
          customActions={[
            (record) => (
              <Tooltip title="Resend activation email">
                <ReloadOutlined onClick={() => !loading && resendCallback(record)} />
              </Tooltip>
            ),
          ]}
        />
        {loading && <Loading />}
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
