import { Alert, Button } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/natourwine-logo.png";
import { authProvider, ILoginUserToken } from "../../common/authProvider";
import { LoginApiInputData } from "../../common/interfaces/web-api";
import { ILoginFormProps } from "./Login.interfaces";
import { UserNameInputComponent, PasswordInputComponent } from "./Form.fields";
import { LoginDal } from "./Login.dal";
import { AxiosError } from "axios";
import { Loading } from "../../common/components/Loading";

const initialFormValues: ILoginFormProps = { userName: "", password: "" };

export const Login: React.FC = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [isError, setErrorFlag] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const history = useHistory();

  const onFormSubmit = async (values: ILoginFormProps): Promise<void> => {
    if (fetching) return;
    setFetching(true);

    const data: LoginApiInputData = {
      userName: values.userName,
      password: values.password,
    };

    try {
      const loginResult = await LoginDal.login(data);
      authProvider.login(loginResult as ILoginUserToken);
      history.push("/", { fromLogin: true });
    } catch (err) {
      const e = err as AxiosError;
      setFetching(false);
      setErrorFlag(true);
      if (e.response?.status === 401) {
        setErrorMsg("E-mail o password errati");
        return;
      }
      setErrorMsg("Errore imprevisto");
      console.error(e.message);
    }
  };

  return (
    <>
      <div className="login-container">
        <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
          {() => (
            <div className="panel-login">
              <div className="panel-head">
                <img className="login-logo" src={logo} alt="logo" style={{margin: 20}} />
              </div>
              <div className="tsid-info">
                <p style={{ textAlign: "center" }}>NaTourWine web application</p>
              </div>
              <div className="panel-body">
                <Form className="login-form">
                  <Field
                    name="userName"
                    label="E-mail"
                    component={UserNameInputComponent}
                    placeholder="n.cognome@esempio.com"
                  />
                  <div className="divider" />
                  <Field name="password" component={PasswordInputComponent} placeholder="**********" />
                  {isError && <Alert message={errorMsg} type="error" showIcon />}
                  <div className="divider" />
                  <div className="login-buttons-group">
                    <Button type="primary" htmlType="submit">
                      Accedi
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
      {fetching && <Loading />}
    </>
  );
};
