import { ArrowLeftOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { useHistory } from "react-router";
import { IFlexPanelMiddleProps } from "./FlexPanelMiddle.interfaces";

export const FlexPanelMiddle: React.FC<IFlexPanelMiddleProps> = ({
  title,
  panelClassName,
  children,
  backButton,
  backButtonFn,
}) => {
  const history = useHistory();
  return (
    <div className="flex-panel flex-panel-middle">
      <div className="panel-container">
        <div className={`panel ${panelClassName}`}>
          {title && (
            <div className="panel-head">
              {backButton && (
                <Tooltip title="Go Back">
                  <ArrowLeftOutlined
                    className="backbutton"
                    onClick={() => (backButtonFn ? backButtonFn() : history.goBack())}
                  />
                </Tooltip>
              )}
              <div className="panel-head-content-div">
                <span className="panel-title">{title}</span>
              </div>
            </div>
          )}
          <div className="panel-body">
            <div style={{ margin: "10px" }}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
