import { IReduxAction } from '../reduxStore.interfaces';
import { AuthConstants, initialState } from '../costants/auth.constants';
import { IAuthActionsPayload, IAuthState } from '../interfaces/auth.interfaces';

type Actions = keyof typeof AuthConstants;

export const authReducer = (state: IAuthState = initialState, action: IReduxAction<Actions, IAuthActionsPayload>): IAuthState => {
  switch (action.type) {
    case AuthConstants.Login:
      return {
        ...state,
        ...action.payload,
      };

    case AuthConstants.SendRequest:
      return { ...state, requestStatus: { isError: false, isFinish: false, isLoading: true } };
    case AuthConstants.RequestOk:
      return { ...state, requestStatus: { ...state.requestStatus, isFinish: true, isLoading: false } };
    case AuthConstants.RequestKO:
      return { ...state, requestStatus: { isError: true, isFinish: true, isLoading: false } };
    case AuthConstants.Logout:
      return { ...initialState };
    default:
      return { ...state };
  }
};
