import { FC } from "react";
import { FlexContainer } from "../../common/components/FlexContainer";
import { FlexPanelMiddle } from "../../common/components/FlexPanelMiddle";
import { IJsonFormProps, jsonFormFactory } from "../../hoc/FormFactory";
import { formSchemaDal } from "./FormSchema.dal";

const JsonSchemaFormComponent: FC<IJsonFormProps> = jsonFormFactory(formSchemaDal, "form-schemas");

export const JsonSchemasForm: FC<{ match: any }> = (props) => {
  return (
    <FlexContainer>
      <FlexPanelMiddle title={"Form Schemas editing"} panelClassName="md-card-3" backButton>
        <JsonSchemaFormComponent {...props} />
      </FlexPanelMiddle>
    </FlexContainer>
  );
};
