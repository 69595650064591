import { Asset } from "../../interfaces";
import L from "leaflet";
import defaultImg from "../../../assets/icons/Default.png";

export const getMarkerIcon = (iconName: string, markers: Asset[], alwaysDefault: boolean = false) => {
  const selectedAsset = markers.find((m) => iconName === m.name);

  return L.icon({
    iconUrl: (selectedAsset && selectedAsset.base64Data) || defaultImg,
    iconSize: [30, 45],
    iconAnchor: [17, 46],
  });
};
