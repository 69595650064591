import "./loading.less";
import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export const Loading: FC<IVploading> = props => {
  const { neutralmode } = props;
  return (
    <div className={`loading ${neutralmode ? 'neutral' : ''}`} key="LoadingStyle" {...props}>
      <div className="overlay-content">
        {neutralmode ? <Spin size="large"/> : <LoadingOutlined spin />}
      </div>
    </div>
  );
};

export interface IVploading {
  neutralmode?: boolean;
}
